<template>
	<div :class="['cw-app-wrapper', isPreContract ? 'pre-contract' : '']">
		<!-- container -->
		<MStepper />
		<v-dialog v-model="monChk" persistent scrollable>
			<v-card class="aalert scr">
				<v-card-title class="border">
					주문 재 접수 건이 확인 되었습니다.
				</v-card-title>
				<v-card-text class="type_left px-6">
					아래의 재 접수건 및 유의사항을 확인하시고, 재 접수 주문건이 아닌 경우
					소속되어 있는 지원 조직 및 센터로 반드시 변경 요청해 주세요.
					<div class="as_card_table my-6">
						<div class="list">
							<div class="list_th">
								<div class="as_th">주문번호</div>
								<div class="as_th">접수기간</div>
							</div>
							<div class="src">
								<div class="list_td" v-for="(item, i) in monthList" :key="i">
									<div class="as_td">{{ item['3MON_ORDER'] }}</div>
									<div class="as_td" v-if="item['3MON_FLAG'] === 'P'">
										14일 이내 재접수
									</div>
									<div class="as_td" v-else-if="item['3MON_FLAG'] === 'S'">
										9개월 이내 재접수
									</div>
									<div class="as_td" v-else>
										{{ item['3MON_FLAG'] }}
									</div>
								</div>
							</div>
						</div>
					</div>
					<h3 class="warnning_head" v-if="type !== 'gallery'">유의사항</h3>
					<dl class="gray_box" v-if="type === 'bb'">
						<dt>렌탈 / 일시불</dt>
						<dd>
							14일 / 9개월 이내 : 재접수 수수료 규정에 따름<br />
							※ 수수료 규정집 확인 必.
						</dd>
					</dl>
					<dl class="gray_box" v-else-if="type !== 'gallery'">
						<dt>렌탈</dt>
						<dd>
							14일 / 9개월 이내 : 실적 건수인정 안됨<br />
							※ 재접수 수수료 규정에 따름
						</dd>
						<dt>일시불</dt>
						<dd>14일 / 9개월 이내 : 영업수수료 50%</dd>
					</dl>
				</v-card-text>
				<v-card-actions class="pt-0">
					<v-spacer></v-spacer>
					<v-btn class="btn_confirm btn_all" @click="accept">확인 </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- modals -->
		<v-dialog width="312" v-model="popPreSubsShow">
			<section class="u-confirm-wrap center">
				<article class="panels">
					<v-card-title>
						사전계약 주문<br />
						설치희망일시 선택 안내
					</v-card-title>
					<v-card-text>
						<ul class="u-confirm-text">
							<li>
								<p>
									사전계약 대상 계정의 소유권 도래월 부터 설치희망일시 선택 및
									&nbsp;배정프로세스 진행이 가능합니다.
								</p>
							</li>
							<li>
								<p>
									배정진행이 가능한 시점에 PUSH 발송 예정이니, 해당 시점에
									&nbsp;모바일주문 화면을 통해서 배정을 진행하여 사전계약 주문을
									&nbsp;확정해 주시기 바랍니다.
								</p>
							</li>
						</ul>
					</v-card-text>
				</article>
				<article class="bottom">
					<v-card-actions>
						<div class="col">
							<v-btn class="btn-confirm" @click="popPreSubsShow = false">
								닫기
							</v-btn>
						</div>
					</v-card-actions>
				</article>
			</section>
		</v-dialog>
		<!-- //modals -->
		<div class="cw-app-section">
			<div class="u-app-title">설치정보</div>
			<div class="u-app-message" v-if="isCowayLifeSolution">
				<h3>코웨이 주문의 설치정보만 선택해 주세요.</h3>
				<p>
					제휴 주문(코웨이라이프)과 관련된 주소지 설정은 코웨이 주문을 완료한 뒤
					이어서 진행할 수 있습니다.
				</p>
			</div>
			<div class="u-app-checkbox" v-if="payList.length > 1">
				<ACheckbox
					:checked="checkedAll"
					@change="changeCheckedAll($event)"
					:label="'모든 주문을 동일 주소에 설치/배송 받습니다.'"
				/>
			</div>
			<!-- 체크 시-->
			<div v-show="checkedAll">
				<div class="u-app-product">
					<ul>
						<li v-for="(titleList, i) in payTitleList" :key="i">
							<span class="idx">
								주문<i>{{ i + 1 }}.</i>
							</span>
							<span class="val">
								{{ titleList.prdNm }} /
								{{ titleList.sellTypeText }}
								{{ titleList.agreeTermText }}
							</span>
						</li>
					</ul>
				</div>

				<div class="u-app-details">
					<v-form :ref="`commonInstForm`">
						<dl class="row">
							<dt>휴대폰번호</dt>
							<dd>
								<v-text-field
									outlined
									dense
									placeholder="휴대폰번호 입력"
									inputmode="numeric"
									maxlength="11"
									minlength="9"
									v-mask="['#########', '##########', '###########']"
									v-model="allSameItems.phone"
									:rules="rules.phone"
								/>
							</dd>
						</dl>
						<dl class="row">
							<dt>전화번호</dt>
							<dd>
								<v-text-field
									outlined
									dense
									placeholder="자택번호를 입력해 주세요. (선택)"
									inputmode="numeric"
									maxlength="20"
									v-mask="['#########', '##########', '###########']"
									v-model="allSameItems.setupTelNumber"
								/>
							</dd>
						</dl>
						<!-- 주소 -->
						<dl class="row">
							<dt>주소</dt>
							<dd>
								<v-btn class="btn_row" @click="openAddrList('common')">
									<em
										class="ico_search ft-black"
										v-if="allSameItems.addrFull.length > 1"
									>
										{{ allSameItems.addrFull }}
									</em>
									<em v-else class="ico_search text_orange">
										{{ allSameItems.emptyAddr }}
									</em>
								</v-btn>
								<p
									class="vaild-msg error"
									v-if="!allSameItems.deliveryItem.allExpress"
								>
									{{ allSameItems.regionalSpcText }}
								</p>
							</dd>
						</dl>
						<!-- 설치처분류 -->
						<dl class="row" v-if="!allSameItems.deliveryItem.allExpress">
							<dt>설치처 분류</dt>
							<dd>
								<MSelectBottom
									title="설치처 분류"
									v-model="allSameItems.setupAreaTypeCode"
									:items="setupAreaTypeItems"
									:onceNo="allSameItems.setupAreaTypeCode"
									@item-selected="handleSetupAreaTypeSelected(0, $event)"
								>
									<template v-if="allSameItems.areaType === undefined">
										<span class="btn_value"></span>
									</template>
									<template v-else>
										<span class="btn_value active">
											<b>{{ allSameItems.areaTypeDisplayText }}</b>
										</span>
									</template>
								</MSelectBottom>
							</dd>
						</dl>
						<!-- 설치희망일시 -->
						<dl class="row full top">
							<dt :class="{ tips: isPreContract }">
								설치희망일시
								<v-btn
									v-if="isPreContract"
									icon
									class="btn-ico-mark"
									@click="preSubGuideOpen()"
								>
									<em class="ir">팝업 열기</em>
								</v-btn>
							</dt>
							<dd>
								<ul class="order-wrap">
									<li
										v-if="
											allSameItems.scheduleInfos.serviceManager.orders.length >
												0
										"
									>
										<div class="order-box">
											<h4>서비스매니저</h4>
											<div
												class="flex"
												v-for="(order, index) in allSameItems.scheduleInfos
													.serviceManager.orders"
												:key="index"
											>
												<p>
													주문{{ order.seq }}.
													<span>{{ order.leaderModelNm }}</span>
												</p>
												<p v-if="isPreContract" class="msg">
													{{ order.preOwnerYm.substr(0, 4) }}-
													{{ order.preOwnerYm.substr(4, 2) }}
													배정 진행 가능
												</p>
											</div>
										</div>
										<v-btn
											v-if="!isPreContract"
											class="btn_row pick"
											@click="openSchPopup('serviceManager')"
										>
											<em
												class="normal"
												v-if="
													allSameItems.scheduleInfos.serviceManager.bookingDate
												"
											>
												{{
													dateToBookingText(
														allSameItems.scheduleInfos.serviceManager
													)
												}}
											</em>
											<em class="normal text_orange" v-else>설치일시 선택</em>
										</v-btn>
									</li>
									<li
										v-if="
											allSameItems.scheduleInfos.delegate3PL.orders.length > 0
										"
									>
										<div class="order-box">
											<h4>외부업체(대형제품)</h4>
											<div
												class="flex"
												v-for="(order, index) in allSameItems.scheduleInfos
													.delegate3PL.orders"
												:key="index"
											>
												<p>
													주문{{ order.seq }}.
													<span>{{ order.leaderModelNm }}</span>
												</p>
												<p v-if="isPreContract" class="msg">
													{{ order.preOwnerYm.substr(0, 4) }}-
													{{ order.preOwnerYm.substr(4, 2) }}
													배정 진행 가능
												</p>
											</div>
										</div>
										<v-btn
											v-if="!isPreContract"
											class="btn_row pick"
											@click="openSchPopup('delegate3PL')"
										>
											<em
												class="normal"
												v-if="
													allSameItems.scheduleInfos.delegate3PL.bookingDate
												"
											>
												{{
													dateToBookingText(
														allSameItems.scheduleInfos.delegate3PL
													)
												}}
											</em>
											<em class="normal text_orange" v-else>설치일시 선택</em>
										</v-btn>
									</li>
									<li
										v-if="allSameItems.scheduleInfos.air3PL.orders.length > 0"
									>
										<div class="order-box">
											<h4>외부업체(청정기)</h4>
											<div
												class="flex"
												v-for="(order, index) in allSameItems.scheduleInfos
													.air3PL.orders"
												:key="index"
											>
												<p>
													주문{{ order.seq }}.
													<span>{{ order.leaderModelNm }}</span>
												</p>
												<p v-if="isPreContract" class="msg">
													{{ order.preOwnerYm.substr(0, 4) }}-
													{{ order.preOwnerYm.substr(4, 2) }}
													배정 진행 가능
												</p>
											</div>
										</div>
										<v-btn
											v-if="!isPreContract"
											class="btn_row pick"
											@click="openSchPopup('air3PL')"
										>
											<em
												class="normal"
												v-if="allSameItems.scheduleInfos.air3PL.bookingDate"
											>
												{{
													dateToBookingText(allSameItems.scheduleInfos.air3PL)
												}}
											</em>
											<em class="normal text_orange" v-else>설치일시 선택</em>
										</v-btn>
									</li>
									<li
										v-if="allSameItems.scheduleInfos.express.orders.length > 0"
									>
										<div class="order-box">
											<h4>택배배송</h4>
											<p
												v-for="(order, index) in allSameItems.scheduleInfos
													.express.orders"
												:key="index"
											>
												주문{{ order.seq }}.
												<span>{{ order.leaderModelNm }}</span>
											</p>
										</div>
									</li>
								</ul>
							</dd>
						</dl>
						<!-- 설치환경정보 -->
						<dl
							class="row full top"
							v-if="!allSameItems.deliveryItem.allExpress"
						>
							<dt>설치환경정보</dt>
							<dd>
								<ul class="install-box">
									<template v-for="(pay, i) in payList">
										<li
											class="wrap_input"
											:key="i"
											v-if="envInfoChk(i, pay) && !deliveryItems[i].isExpress"
										>
											<span
												>주문{{ i + 1 }}. {{ pay.prd.leader_model_nm }}</span
											>
											<v-btn
												dense
												depressed
												height="32"
												class="btn_row_arrow_left"
												@click="openEnvInfo(i, pay)"
											>
												<span
													class="btn_value primary--text"
													v-if="allSameItems.hasEnvInfoArr[i] === true"
												>
													입력완료
												</span>
												<span class="btn_value text_orange" v-else>
													미입력
												</span>
											</v-btn>
											<InstEnvInfo
												:ref="'allSameEnvInfo' + i"
												:no="i"
												:pay="pay"
												@save="saveEnvInfo"
											/>
										</li>
									</template>
								</ul>
							</dd>
						</dl>
						<!-- 설치메모 -->
						<dl class="row full" v-if="!allSameItems.deliveryItem.allExpress">
							<dt>설치메모</dt>
							<dd>
								<div class="text-box expand">
									<v-textarea
										dense
										outlined
										auto-grow
										rows="4"
										class="addr_frm01"
										v-model="allSameItems.memo"
										v-show="allSameItems.show"
										placeholder="설치메모를 입력해 주세요. (선택)"
										:rules="rules.memo"
									/>
								</div>
							</dd>
						</dl>
						<!-- 택배 배송 요청사항  -->
						<dl
							class="row full top"
							v-show="allSameItems.deliveryItem.includeExpress"
						>
							<dt>택배 배송 요청사항</dt>
							<dd>
								<MSelectBottom
									title="배송 요청사항 선택"
									v-model="allSameItems.deliveryItem.reqInfo.typeCode"
									:items="setupExpressTypeItems"
									:onceNo="allSameItems.deliveryItem.reqInfo.typeCode"
									@item-selected="handleSetupDeliveryTypeSelected(0, $event)"
								>
									<template
										v-if="allSameItems.deliveryItem.reqInfo.type === undefined"
									>
										<span class="btn_value">
											<b>요청사항을 선택해 주세요. (선택)</b>
										</span>
									</template>
									<template v-else>
										<span class="btn_value active">
											<b>{{ allSameItems.deliveryItem.reqInfo.displayText }}</b>
										</span>
									</template>
								</MSelectBottom>

								<div
									class="text-box count mt-2"
									v-show="allSameItems.deliveryItem.reqInfo.show"
								>
									<v-textarea
										dense
										outlined
										auto-grow
										rows="2"
										class="addr_frm01"
										placeholder="메시지를 입력해 주세요."
										v-model="allSameItems.deliveryMemo"
										@keyup="deliveryMemoMaxLengthCheck($event, null)"
									/>
									<span class="text-cnt">
										{{ `${allSameItems.deliveryMemo.length}/20` }}
									</span>
								</div>
							</dd>

							<div class="u-app-notice">
								<template v-if="isPreContract">
									<p>
										<em>
											사전계약 주문의 경우, 택배 배송은 기존 계정의 소유권
											&nbsp;도래월에 시작됩니다.
										</em>
									</p>
								</template>
								<template v-else>
									<p>
										<em>
											택배 배송 상품은 오후 2시 이전 주문시 당일 발송합니다.
										</em>
									</p>
									<p>
										<em>택배사 사정에 따라 변동이 있을 수 있습니다.</em>
									</p>
								</template>
							</div>
						</dl>
						<!-- 추천 판매인 -->
						<dl class="row top" v-if="recommendSalesPopView">
							<dt>추천 판매인</dt>
							<dd>
								<v-btn
									class="btn_row"
									@click.stop="openRecommendList('common')"
								>
									<template v-if="allSameItems.recommnedSales.ENAME">
										<em class="text_value">
											{{ allSameItems.recommnedSales.ENAME }}&nbsp;
											{{
												$options.filters.phoneFormat(
													allSameItems.recommnedSales.NUM02,
													0
												)
											}}
										</em>
										<v-btn
											class="btn_remove"
											text
											@click.stop="recommnedSalesDelete('common')"
										>
											<em>삭제</em>
										</v-btn>
									</template>
									<template v-else>
										<em class="ico_search text_gray">
											{{ allSameItems.emptyRecommendSales }}
										</em>
									</template>
								</v-btn>
							</dd>
							<div
								class="u-app-notice"
								v-if="
									isMedicalItemExist &&
										this.allSameItems.recommnedSales.MEDICAL_YN === 'N'
								"
							>
								<p>
									<em>
										해당 판매인은 의료기기 추천판매가 불가합니다.
										<br />(의료기기 제외한 상품에만 추천인 적용)
									</em>
								</p>
							</div>
						</dl>
						<InstAddrList
							:ref="'commonAddrList'"
							:orderCount="payList.length"
							:addrList="addrList"
							@select="selectAddress(0, $event)"
						/>
						<InstRecommendSalesSearch
							:ref="'commonRecommedList'"
							:no="0"
							:recommnedSales="allSameItems.recommnedSales"
							@selectRecommendSales="selectRecommendSales"
						/>
					</v-form>
				</div>
			</div>

			<!-- 미체크 시-->
			<div class="u-app-panels" v-show="!checkedAll">
				<v-expansion-panels accordion v-model="openedPanel" class="mt-3">
					<template v-for="(pay, i) in payList">
						<v-expansion-panel :key="`instForm-${i}`">
							<v-expansion-panel-header
								style="padding:7px 24px 0px !important;"
							>
								<div class="u-app-product">
									<ul>
										<li>
											<span class="idx">
												주문<i>{{ i + 1 }}.</i>
											</span>
											<span class="val">
												{{ pay.prd.leader_model_nm }} /
												{{ pay.payOptions.ET_INPUT.sellTypeText }}
												<template v-if="pay.options.agreeTerm">
													{{ `(${pay.options.agreeTerm.USE_DUTY_MONTH}개월)` }}
												</template>
											</span>
										</li>
									</ul>
								</div>
							</v-expansion-panel-header>
							<v-expansion-panel-content>
								<div class="u-app-details">
									<v-form :key="`instForm${i}`" :ref="`instForm`">
										<!-- 휴대폰번호 -->
										<dl class="row">
											<dt>휴대폰번호</dt>
											<dd>
												<v-text-field
													outlined
													dense
													placeholder="- 없이 숫자만 입력"
													inputmode="numeric"
													maxlength="11"
													minlength="9"
													v-mask="['#########', '##########', '###########']"
													v-model="setupInfos[i].phone"
													:rules="rules.phone"
												/>
											</dd>
										</dl>
										<!-- 전화번호 -->
										<dl class="row">
											<dt>전화번호</dt>
											<dd>
												<v-text-field
													outlined
													dense
													placeholder="자택번호를 입력해 주세요. (선택)"
													inputmode="numeric"
													maxlength="20"
													v-mask="['#########', '##########', '###########']"
													v-model="setupInfos[i].setupTelNumber"
												/>
											</dd>
										</dl>
										<!-- 주소 -->
										<dl class="row ">
											<dt>주소</dt>
											<dd>
												<v-btn class="btn_row" @click="openAddrList(i)">
													<em
														class="ico_search ft-black"
														v-if="setupInfos[i].addrFull.length > 1"
													>
														{{ setupInfos[i].addrFull }}
													</em>
													<em v-else class="ico_search text_orange">
														{{ setupInfos[i].emptyAddr }}
													</em>
												</v-btn>
												<p
													class="vaild-msg error"
													style="text-align: left;"
													v-if="!deliveryItems[i].isExpress"
												>
													{{ setupInfos[i].regionalSpcText }}
												</p>
											</dd>
										</dl>
										<!-- 설치처분류 -->
										<dl class="row top" v-if="!deliveryItems[i].isExpress">
											<dt>설치처 분류</dt>
											<dd>
												<MSelectBottom
													title="설치처 분류"
													v-model="setupAreaTypeCode[i]"
													:items="setupAreaTypeItems"
													:onceNo="setupAreaTypeCode[i]"
													@item-selected="
														handleSetupAreaTypeSelected(i, $event)
													"
												>
													<template v-if="setupInfos[i].areaType === undefined">
														<span class="btn_value"></span>
													</template>
													<template v-else>
														<span class="btn_value active">
															<b>{{ setupInfos[i].areaTypeDisplayText }}</b>
														</span>
													</template>
												</MSelectBottom>
											</dd>
										</dl>
										<!-- 설치희망일시 -->
										<dl class="row" v-if="!deliveryItems[i].isExpress">
											<dt>설치희망일시</dt>
											<dd :class="{ btns: isPreContract }">
												<v-btn
													v-if="isPreContract"
													disabled
													class="btn_row active"
												>
													<em class="normal">
														{{ deliveryInfos[i].preOwnerYm.substr(0, 4) }}-
														{{ deliveryInfos[i].preOwnerYm.substr(4, 2) }}
														배정 진행 가능</em
													>
													<!-- <em class="normal text_orange">설치일시 선택</em> -->
												</v-btn>
												<v-btn
													v-else
													class="btn_row pick"
													@click="openSchPopup(i)"
												>
													<em
														class="normal"
														v-if="deliveryInfos[i].bookingDate"
														>{{ dateToBookingText(deliveryInfos[i]) }}</em
													>
													<em class="normal text_orange" v-else
														>설치일시 선택</em
													>
												</v-btn>
												<v-btn
													v-if="isPreContract"
													icon
													class="btn-ico-mark"
													@click="preSubGuideOpen()"
												>
													<em class="ir">팝업 열기</em>
												</v-btn>
											</dd>
										</dl>
										<!-- 설치환경정보 -->
										<dl
											class="row"
											v-if="envInfoChk(i, pay) && !deliveryItems[i].isExpress"
										>
											<dt>설치환경정보</dt>
											<dd class="wrap_input mt-0">
												<v-btn
													dense
													depressed
													height="48"
													class="btn_row_arrow_left"
													@click="openEnvInfo(i, pay)"
												>
													<span
														class="btn_value primary--text"
														v-if="hasEnvInfoArr[i] === true"
													>
														입력완료
													</span>
													<span class="btn_value text_orange" v-else>
														미입력
													</span>
												</v-btn>
											</dd>
										</dl>
										<!-- 설치메모 -->
										<dl class="row full" v-if="!deliveryItems[i].isExpress">
											<dt>설치메모</dt>
											<dd>
												<div class="text-box expand">
													<v-textarea
														dense
														outlined
														auto-grow
														rows="4"
														class="addr_frm01"
														v-model="setupInfos[i].memo"
														:ref="'setupMemo' + i"
														v-show="setupInfos[i].show"
														placeholder="설치메모를 입력해 주세요. (선택)"
														:rules="rules.memo"
													/>
												</div>
											</dd>
										</dl>
										<!-- 택배 배송 요청사항  -->
										<dl class="row full top" v-if="deliveryItems[i].isExpress">
											<dt>택배 배송 요청사항</dt>
											<dd>
												<MSelectBottom
													title="배송 요청사항 선택"
													v-model="deliveryItems[i].reqInfo.typeCode"
													:items="setupExpressTypeItems"
													:onceNo="deliveryItems[i].reqInfo.typeCode"
													@item-selected="
														handleSetupDeliveryTypeSelected(i, $event)
													"
												>
													<template
														v-if="deliveryItems[i].reqInfo.type === undefined"
													>
														<span class="btn_value">
															<b>요청사항을 선택해 주세요. (선택)</b>
														</span>
													</template>
													<template v-else>
														<span class="btn_value active">
															<b>{{ deliveryItems[i].reqInfo.displayText }}</b>
														</span>
													</template>
												</MSelectBottom>

												<div
													class="text-box count mt-2"
													v-show="deliveryItems[i].reqInfo.show"
												>
													<v-textarea
														dense
														outlined
														auto-grow
														rows="2"
														class="addr_frm01"
														placeholder="메시지를 입력해 주세요."
														v-model="setupInfos[i].deliveryMemo"
														@keyup="deliveryMemoMaxLengthCheck($event, i)"
													/>
													<span class="text-cnt">
														{{ `${setupInfos[i].deliveryMemo.length}/20` }}
													</span>
												</div>
											</dd>

											<div class="u-app-notice">
												<template v-if="isPreContract">
													<p>
														<em>
															사전계약 주문의 경우, 택배 배송은 기존 계정의
															&nbsp;소유권 도래월에 시작됩니다.
														</em>
													</p>
												</template>
												<template v-else>
													<p>
														<em>
															택배 배송 상품은 오후 2시 이전 주문시 당일
															발송합니다.
														</em>
													</p>
													<p>
														<em>택배사 사정에 따라 변동이 있을 수 있습니다.</em>
													</p>
												</template>
											</div>
										</dl>
										<!-- 추천 판매인 -->
										<dl class="row top" v-if="recommendSalesPopView">
											<dt>추천 판매인</dt>
											<dd>
												<v-btn
													class="btn_row"
													@click.stop="openRecommendList(i)"
												>
													<template v-if="setupInfos[i].recommnedSales.ENAME">
														<em class="text_value">
															{{ setupInfos[i].recommnedSales.ENAME }}&nbsp;
															{{
																$options.filters.phoneFormat(
																	setupInfos[i].recommnedSales.NUM02,
																	0
																)
															}}
														</em>
														<v-btn
															class="btn_remove"
															text
															@click.stop="recommnedSalesDelete(i)"
														>
															<em>삭제</em>
														</v-btn>
													</template>
													<template v-else>
														<em class="ico_search text_gray">
															{{ setupInfos[i].emptyRecommendSales }}
														</em>
													</template>
												</v-btn>
											</dd>
											<div
												class="u-app-notice"
												v-if="
													isMedicalItemExist &&
														setupInfos[i].recommnedSales.MEDICAL_YN === 'N'
												"
											>
												<p>
													<em>
														해당 판매인은 의료기기 추천판매가 불가합니다.
														<br />(의료기기 제외한 상품에만 추천인 적용)
													</em>
												</p>
											</div>
										</dl>
										<InstEnvInfo
											:ref="'envInfo' + i"
											:no="i"
											:pay="pay"
											@save="saveEnvInfo"
										/>
										<InstAddrList
											:ref="'addrList' + i"
											:orderCount="payList.length"
											:addrList="addrList"
											@select="selectAddress(i, $event)"
										/>
										<InstRecommendSalesSearch
											:ref="'recommedList' + i"
											:no="i"
											:recommnedSales="setupInfos[i].recommnedSales"
											@selectRecommendSales="selectRecommendSales"
										/>
									</v-form>
								</div>
							</v-expansion-panel-content>
						</v-expansion-panel>
					</template>
				</v-expansion-panels>
			</div>
		</div>
		<!-- //container -->

		<!-- bottom -->
		<div class="cw-app-fixed">
			<div class="u-btns-group">
				<div class="column">
					<v-btn large depressed class="u-btn-text" @click="ListBack">
						이전
					</v-btn>
				</div>
				<div class="column">
					<v-btn large depressed class="u-btn-active" @click="next()">
						주문 진행
					</v-btn>
				</div>
			</div>
		</div>
		<!-- //bottom -->
		<section class="u-toast-area bottom">
			<v-snackbar :timeout="3000" v-model="isToastActive">
				<v-card class="toast-box">
					<span>
						고객님과 상담 중인 추천판매인이
						<br />자동으로 등록되었습니다.
					</span>
				</v-card>
			</v-snackbar>
		</section>

		<!-- 팝업 컴포넌트  -->
		<RecommendConfirmDialog ref="recommendConfirmPop" />
		<RecommendConfirmInputDialog ref="recommendConfirmInputPop" />
		<!-- //팝업 컴포넌트  -->
	</div>
</template>

<script>
import InstEnvInfo from '@/views/inst/InstEnvInfo'
import InstAddrList from '@/views/inst/InstAddrList'
import InstRecommendSalesSearch from '@/views/inst/InstRecommendSalesSearch'
import RecommendConfirmDialog from '@/components/biz/dialog/RecommendConfirmDialog'
import RecommendConfirmInputDialog from '@/components/biz/dialog/RecommendConfirmInputDialog'

import {
	mOrderInfoByEtc,
	postWebOrderInfoById,
	fetchWebOrderInfoById
} from '@/apis/order.status.api'
import {
	postOrder,
	fetchAddrList,
	fetchMemoList,
	preOrderSubStatus,
	fetchRegionalSpcText
} from '@/apis/order.inst.api'
import { fetchCustSetup } from '@/apis/order.verify.api'
import { required, maxLength, phoneNumber, phoneLength } from '@/utils/rules'
import {
	getPrdName,
	isWaterPurifier,
	isAirPurifier,
	isWaterSoftener,
	isBidet,
	isMattress,
	isChair,
	isClothesPurifier,
	isFootBath,
	getEnvInfoActive,
	getMessageByStatusCode
} from '@/utils/ec'
import { registerCallbackToApp } from '@/utils/navigation'
import { dateToBookingText } from '@/utils/schedule.js'
import filters from '@/mixins/filters'
import moment from 'moment'
import { getPreContractSalesChk } from '@/apis/pre.contract.api'
import { getRecommendSalesInfo, getOrderChkSmart } from '@/apis/order.inst.api'
import { isParameterEmpty } from '@/utils/common'
import { fetchAidOrderCheck } from '@/apis/coway.life.api'

export default {
	name: 'InstPage',
	mixins: [filters],
	components: {
		InstEnvInfo,
		InstAddrList,
		InstRecommendSalesSearch,
		RecommendConfirmDialog,
		RecommendConfirmInputDialog
	},
	data() {
		return {
			setupAreaTypeItems: [
				// { code: '1', text: '주방' },
				{ code: '2', text: '가정' },
				{ code: '7', text: '공공시설' },
				{ code: '3', text: '사무실' },
				{ code: '5', text: '업소' },
				{ code: '4', text: '의료기관' }
				// { code: '6', text: '학교(면제)' },
				// { code: '7', text: '공공시설(관공서등)' },
				// { code: '8', text: '닥터관리처' },
				// { code: '9', text: '군부대' },
				// { code: 'A', text: '학교(면제불가)' }
				// { code: 'B', text: '기타' }
			],
			setupExpressTypeItems: [
				{ code: '1', text: '메시지 직접 입력하기' },
				{ code: '2', text: '부재 시 경비실에 맡겨주세요.' },
				{ code: '3', text: '부재 시 문 앞에 놔주세요.' },
				{ code: '4', text: '배송 전에 미리 연락주세요.' },
				{ code: '5', text: '배송 전에 전화주시거나 문자 남겨 주세요.' },
				{ code: '6', text: '선택 안함' }
			],
			rules: {
				name: [v => required(v)],
				phone: [v => phoneLength(v, 11), v => phoneNumber(v)],
				date: [v => required(v)],
				memo: [
					v => {
						if (v) return maxLength(v, 128)
						else return true
					}
				]
			},
			openedPanel: 0,
			payList: this.$store.getters['pay/payProducts'],
			deliveryInfos: JSON.parse(
				JSON.stringify(this.$store.getters['schedule/deliveryInfos'])
			),
			setupMemoItems: [],
			preDateCheck: [],
			setupInfos: [],
			setupMemoSelectCode: [],
			setupAreaTypeCode: [],
			setupScheduleInfo: [],
			addrList: [],
			minDate: [],
			maxDate: [],
			selectDate: [],
			hasEnvInfoArr: [],
			businessDays: [],
			webOrderSum: {},
			monChk: false,
			resolve: null,
			reject: null,
			monthList: [],
			mobOrderNo: this.$store.getters['verify/mobOrderNo'],
			checkedAll: false,
			allSameItems: {
				phone: '',
				setupTelNumber: '',
				name: '',
				address: '',
				addrFull: '',
				addr: '',
				zipSeq: '',
				emptyAddr: '주소 입력',
				emptyRecommendSales: '추천 판매인 입력 (선택)',
				recommnedSales: {},
				regionalSpcText: '',
				areaType: '',
				areaTypeDisplayText: '',
				setupAreaTypeCode: '',
				memo: '',
				deliveryMemo: '',
				deliveryItem: {
					includeExpress: false,
					allExpress: false,
					reqInfo: {
						type: undefined,
						typeCode: '',
						displayText: '',
						show: false
					}
				},
				show: true,
				hasEnvInfoArr: [],
				setupInfos: [],
				deliveryInfos: [],
				scheduleInfos: {
					serviceManager: {
						orders: [],
						goodsCodeList: [],
						goodsNameList: [],
						prodList: [],
						bookingDate: undefined,
						bookingSlot: undefined,
						key: undefined,
						isFuneralComine: false,
						paymentType: [],
						promotionCode: [],
						electricRangeType: []
					},
					delegate3PL: {
						orders: [],
						goodsCodeList: [],
						goodsNameList: [],
						prodList: [],
						bookingDate: undefined,
						bookingSlot: undefined,
						key: undefined,
						isFuneralComine: false,
						paymentType: [],
						promotionCode: [],
						electricRangeType: []
					},
					air3PL: {
						orders: [],
						goodsCodeList: [],
						goodsNameList: [],
						prodList: [],
						bookingDate: undefined,
						bookingSlot: undefined,
						key: undefined,
						isFuneralComine: false,
						paymentType: [],
						promotionCode: [],
						electricRangeType: []
					},
					express: {
						orders: []
					}
				}
			},
			deliveryItems: [],
			popPreSubsShow: false,
			isPreContract: this.$store.state['verify'].customer.orderType === 'C',
			recommendSalesPopView: this.$store.getters['common/type'] === 'gallery',
			type: this.$store.getters['common/type'],
			isToastActive: false,
			isCowayLifeSolution: false,
			isMedicalItemExist: false
		}
	},
	computed: {
		payTitleList() {
			return this.payList.map(pay => {
				let prdNm = pay.prd.leader_model_nm
				let sellTypeText = pay.payOptions.ET_INPUT.sellTypeText
				let agreeTermText = ''
				if (pay.options.agreeTerm) {
					agreeTermText = `(${pay.options.agreeTerm.USE_DUTY_MONTH}개월)`
				}
				return {
					prdNm: prdNm,
					sellTypeText: sellTypeText,
					agreeTermText: agreeTermText
				}
			})
		}
	},
	created() {
		this.initAppPage()
		window.$updateDeliveryInfos = this.updateDeliveryInfos.bind(this)

		// [BEGIN] cowayLifeSolution 상품 있는지 체크
		const cowayLifeSolution =
			this.$store.getters['pay/funeralPayProducts'] || []
		this.isCowayLifeSolution = cowayLifeSolution.length > 0
		// [END] cowayLifeSolution 상품 있는지 체크
		this.setupMemoItems = [
			{
				code: '',
				text: '설치 메모',
				ORDER_NO: '',
				ORDER_DT: '',
				CUST_REQ_DESC: ''
			}
		]

		// 단건 및 다건개별 세팅
		const name = this.$store.getters['verify/custName']
		const phone = this.$store.getters['verify/custPhone']
		const custType = this.$store.state['verify'].customer.custType
		this.payList = this.$store.getters['pay/payProducts']
		this.payList.forEach((pay, i) => {
			// CWMORDER-145 의료기기 안마베드 대응
			this.isMedicalItemExist =
				pay?.esProd?.PRDHA?.indexOf('005111') === 0
					? true
					: this.isMedicalItemExist

			this.$set(this.setupInfos, i, {
				prdNameKr: this.getPrdName(pay.options.prdha),
				prdName: pay.prd.prod_nm,
				leaderModelNm: pay.prd.leader_model_nm,
				name: name,
				phone: phone,
				setupTelNumber: '',
				addrFull: '',
				emptyAddr: '주소 입력',
				emptyRecommendSales: '추천 판매인 입력 (선택)',
				recommnedSales: {},
				regionalSpcText: '',
				default: false,
				memo: '',
				deliveryMemo: '',
				show: true,
				select: this.setupMemoItems[0], // 직접 입력
				selectDisplayText: this.setupMemoItems[0].text,
				areaType:
					custType === 'P'
						? this.setupAreaTypeItems[0]
						: this.setupAreaTypeItems[2], // 가정
				areaTypeDisplayText:
					custType === 'P'
						? this.setupAreaTypeItems[0].text
						: this.setupAreaTypeItems[2].text,
				envInfo: undefined,
				instType: ''
			})
			this.setupMemoSelectCode[i] = this.setupMemoItems[0].code
			if (custType === 'P') {
				this.setupAreaTypeCode[i] = '2'
			} else {
				this.setupAreaTypeCode[i] = '3'
			}
			this.hasEnvInfoArr[i] = false
			this.$set(this.deliveryItems, i, {
				isExpress: this.deliveryInfos[i]?.workerType === 'EXPRESS',
				reqInfo: {
					type: undefined,
					typeCode: '',
					displayText: '',
					show: false
				}
			})
		})

		// 다건동일 세팅
		if (this.payList.length > 1) {
			this.checkedAll = true

			this.allSameItems.setupInfos = JSON.parse(JSON.stringify(this.setupInfos))
			this.allSameItems.phone = this.setupInfos[0].phone
			this.allSameItems.name = this.setupInfos[0].name
			this.allSameItems.areaType = this.setupInfos[0].areaType
			this.allSameItems.areaTypeDisplayText = this.setupInfos[0].areaTypeDisplayText
			this.allSameItems.setupAreaTypeCode = this.setupAreaTypeCode[0]

			this.deliveryInfos.forEach((deliveryInfo, index) => {
				deliveryInfo.defaultGoodsCode = this.getDefaultGoodsCodeList(
					this.payList[index]
				)
				if (deliveryInfo.workerType === 'SERVICE_MANAGER') {
					this.allSameItems.scheduleInfos.serviceManager.orders.push({
						seq: index + 1,
						prodNm: this.payList[index].prd.prod_nm,
						leaderModelNm: this.payList[index].prd.leader_model_nm,
						preOwnerYm: deliveryInfo.preOwnerYm
					})
					this.allSameItems.scheduleInfos.serviceManager.goodsCodeList.push(
						''.concat(
							this.getDefaultGoodsCodeList(this.payList[index]),
							deliveryInfo.prdOptionItemCdListStr !== ''
								? `@${deliveryInfo.prdOptionItemCdListStr}`
								: ''
						)
					)
					this.allSameItems.scheduleInfos.serviceManager.goodsNameList.push(
						this.payList[index].prd.prod_nm
					)
					this.allSameItems.scheduleInfos.serviceManager.prodList.push(
						deliveryInfo.prodh
					)
					this.allSameItems.scheduleInfos.serviceManager.isFuneralComine = !this
						.allSameItems.scheduleInfos.serviceManager.isFuneralComine
						? deliveryInfo.isFuneralComine
						: this.allSameItems.scheduleInfos.serviceManager.isFuneralComine
					deliveryInfo.paymentType !== ''
						? this.allSameItems.scheduleInfos.serviceManager.paymentType.push(
								deliveryInfo.paymentType
						  )
						: false
					deliveryInfo.promotionCode !== ''
						? this.allSameItems.scheduleInfos.serviceManager.promotionCode.push(
								deliveryInfo.promotionCode
						  )
						: false
					deliveryInfo.electricRangeType !== ''
						? this.allSameItems.scheduleInfos.serviceManager.electricRangeType.push(
								deliveryInfo.electricRangeType
						  )
						: false
				} else if (deliveryInfo.workerType === 'DELEGATE_3PL') {
					this.allSameItems.scheduleInfos.delegate3PL.orders.push({
						seq: index + 1,
						prodNm: this.payList[index].prd.prod_nm,
						leaderModelNm: this.payList[index].prd.leader_model_nm,
						preOwnerYm: deliveryInfo.preOwnerYm
					})
					this.allSameItems.scheduleInfos.delegate3PL.goodsCodeList.push(
						''.concat(
							this.getDefaultGoodsCodeList(this.payList[index]),
							deliveryInfo.prdOptionItemCdListStr !== ''
								? `@${deliveryInfo.prdOptionItemCdListStr}`
								: ''
						)
					)
					this.allSameItems.scheduleInfos.delegate3PL.goodsNameList.push(
						this.payList[index].prd.prod_nm
					)
					this.allSameItems.scheduleInfos.delegate3PL.prodList.push(
						deliveryInfo.prodh
					)
					this.allSameItems.scheduleInfos.delegate3PL.isFuneralComine = !this
						.allSameItems.scheduleInfos.delegate3PL.isFuneralComine
						? deliveryInfo.isFuneralComine
						: this.allSameItems.scheduleInfos.delegate3PL.isFuneralComine
					deliveryInfo.paymentType !== ''
						? this.allSameItems.scheduleInfos.delegate3PL.paymentType.push(
								deliveryInfo.paymentType
						  )
						: false
					deliveryInfo.promotionCode !== ''
						? this.allSameItems.scheduleInfos.delegate3PL.promotionCode.push(
								deliveryInfo.promotionCode
						  )
						: false
					deliveryInfo.electricRangeType !== ''
						? this.allSameItems.scheduleInfos.delegate3PL.electricRangeType.push(
								deliveryInfo.electricRangeType
						  )
						: false
				} else if (deliveryInfo.workerType === 'AIR_3PL') {
					this.allSameItems.scheduleInfos.air3PL.orders.push({
						seq: index + 1,
						prodNm: this.payList[index].prd.prod_nm,
						leaderModelNm: this.payList[index].prd.leader_model_nm,
						preOwnerYm: deliveryInfo.preOwnerYm
					})
					this.allSameItems.scheduleInfos.air3PL.goodsCodeList.push(
						''.concat(
							this.getDefaultGoodsCodeList(this.payList[index]),
							deliveryInfo.prdOptionItemCdListStr !== ''
								? `@${deliveryInfo.prdOptionItemCdListStr}`
								: ''
						)
					)
					this.allSameItems.scheduleInfos.air3PL.goodsNameList.push(
						this.payList[index].prd.prod_nm
					)
					this.allSameItems.scheduleInfos.air3PL.prodList.push(
						deliveryInfo.prodh
					)
					this.allSameItems.scheduleInfos.air3PL.isFuneralComine = !this
						.allSameItems.scheduleInfos.air3PL.isFuneralComine
						? deliveryInfo.isFuneralComine
						: this.allSameItems.scheduleInfos.air3PL.isFuneralComine
					deliveryInfo.paymentType !== ''
						? this.allSameItems.scheduleInfos.air3PL.paymentType.push(
								deliveryInfo.paymentType
						  )
						: false
					deliveryInfo.promotionCode !== ''
						? this.allSameItems.scheduleInfos.air3PL.promotionCode.push(
								deliveryInfo.promotionCode
						  )
						: false
					deliveryInfo.electricRangeType !== ''
						? this.allSameItems.scheduleInfos.air3PL.electricRangeType.push(
								deliveryInfo.electricRangeType
						  )
						: false
				} else if (deliveryInfo.workerType === 'EXPRESS') {
					this.allSameItems.scheduleInfos.express.orders.push({
						seq: index + 1,
						prodNm: this.payList[index].prd.prod_nm,
						leaderModelNm: this.payList[index].prd.leader_model_nm
					})
				}
			})

			const expressLength = this.deliveryInfos.filter(
				v => v.workerType === 'EXPRESS'
			).length
			this.allSameItems.deliveryItem.includeExpress = expressLength > 0
			this.allSameItems.deliveryItem.allExpress =
				expressLength === this.setupInfos.length
		}
	},
	async mounted() {
		if (this.isParameterEmpty(this.mobOrderNo)) return
		if (this.recommendSalesPopView) await this.getPreRecommendtSalesInfo()
		await this.getAddrList()
		await this.getMemoList()

		let res = await fetchCustSetup(this.mobOrderNo)
		if (res.resultObject.data.setup) {
			const regionalSpc = await fetchRegionalSpcText(
				res.resultObject.data.setup.zipseq
			)
			const regionalSpcText = regionalSpc.resultObject.E_DESC_TXT
			if (res.resultObject.data.setup.addr1.trim() !== '') {
				const altRes = await this.$alert({
					message: `고객이 등록한 설치처 정보가 있습니다. 해당 정보를 다시 한번 확인해 주시기 바랍니다. 해당 정보로 주문 및 설치를 진행하시겠습니까? <br/><br/>${res.resultObject.data.setup.addr1} ${res.resultObject.data.setup.addr2}`,
					okText: '확인',
					cancelText: '취소'
				})

				if (!altRes) {
					return
				}

				this.setupInfos.forEach((_val, i) => {
					this.setupInfos[i].address = {
						ADDR1:
							res.resultObject.data.setup.addr1.trim() !== ''
								? res.resultObject.data.setup.addr1
								: '',
						ADDR2:
							res.resultObject.data.setup.addr2.trim() !== ''
								? res.resultObject.data.setup.addr2
								: '',
						ZIP_SEQ: res.resultObject.data.setup.zipseq,
						KUNNR: this.$store.getters['verify/custNo'],
						NAME: res.resultObject.data.setup.name,
						POST_CODE: res.resultObject.data.setup.postCode,
						TEL_NUMBER: res.resultObject.data.setup.telNumber,
						TYPE: 'R'
					}

					if (res.resultObject.data.setup.addr1.trim() !== '') {
						this.setupInfos[
							i
						].addrFull = `${res.resultObject.data.setup.addr1} ${res.resultObject.data.setup.addr2}`
					}

					this.setupInfos[i].addr = undefined
					this.setupInfos[i].regionalSpcText = regionalSpcText
					this.setupInfos[i].name = res.resultObject.data.setup.name
					this.setupInfos[i].phone = res.resultObject.data.setup.telNumber
					this.setupInfos[
						i
					].setupTelNumber = res.resultObject.data.setup.setupTelNumber.trim()
				})

				this.allSameItems.address = this.setupInfos[0].address
				this.allSameItems.addrFull = this.setupInfos[0].addrFull
				this.allSameItems.addr = undefined
				this.allSameItems.regionalSpcText = regionalSpcText
				this.allSameItems.name = this.setupInfos[0].name
				this.allSameItems.phone = this.setupInfos[0].phone
				this.allSameItems.setupTelNumber = this.setupInfos[0].setupTelNumber
				this.allSameItems.setupInfos = JSON.parse(
					JSON.stringify(this.setupInfos)
				)
			}
		}
	},
	methods: {
		getPrdName,
		isWaterPurifier,
		isAirPurifier,
		isWaterSoftener,
		isBidet,
		isMattress,
		isChair,
		isClothesPurifier,
		isFootBath,
		getEnvInfoActive,
		getMessageByStatusCode,
		dateToBookingText,
		isParameterEmpty,
		updateDeliveryInfos: function(result) {
			sessionStorage.removeItem('schedule')
			this.$log(result)
			const index = result.bookingIndex
			if (this.checkedAll) {
				this.allSameItems.scheduleInfos[index].bookingDate = result.bookingDate
				this.allSameItems.scheduleInfos[index].bookingSlot = result.bookingSlot
				this.allSameItems.scheduleInfos[index].bookingSlotTime =
					result.bookingSlotTime
				this.allSameItems.scheduleInfos[index].key = result.key
				this.allSameItems.scheduleInfos[index].address = result.address
				this.allSameItems.scheduleInfos[index].zipCode = result.zipCode

				this.$set(
					this.allSameItems.scheduleInfos,
					index,
					this.allSameItems.scheduleInfos[index]
				)
			} else {
				this.deliveryInfos[index].bookingDate = result.bookingDate
				this.deliveryInfos[index].bookingSlot = result.bookingSlot
				this.deliveryInfos[index].bookingSlotTime = result.bookingSlotTime
				this.deliveryInfos[index].key = result.key
				this.deliveryInfos[index].address = result.address
				this.deliveryInfos[index].zipCode = result.zipCode

				this.$set(this.deliveryInfos, index, this.deliveryInfos[index])
			}
		},
		async openSchPopup(index) {
			let addr, address
			if (this.checkedAll) {
				addr = this.allSameItems.addr
				address = this.allSameItems.address
			} else {
				addr = this.setupInfos[index].addr
				address = this.setupInfos[index].address
			}

			if (!(addr || address)) {
				await this.$alert({
					message: `주소 먼저 입력 후,<br>설치일시를 선택해 주세요.`
				})
				return
			}

			const zipSeq = addr ? addr.zipSeq : address.ZIP_SEQ
			let workerType = ''
			if (this.checkedAll) {
				workerType =
					index === 'serviceManager'
						? 'SERVICE_MANAGER'
						: index === 'air3PL'
						? 'AIR_3PL'
						: 'DELEGATE_3PL'
			} else {
				workerType = this.deliveryInfos[index].workerType
			}

			const goodsCodeList = this.checkedAll
				? this.allSameItems.scheduleInfos[index].goodsCodeList
				: ''.concat(
						this.getDefaultGoodsCodeList(this.payList[index]),
						this.deliveryInfos[index].prdOptionItemCdListStr !== ''
							? `@${this.deliveryInfos[index].prdOptionItemCdListStr}`
							: ''
				  )
			const goodsNameList = this.checkedAll
				? this.allSameItems.scheduleInfos[index].goodsNameList
				: this.payList[index].prd.prod_nm
			const reqAddress = addr ? addr.roadAddress : address.ADDR1
			const zipCode = addr ? addr.zonecode : address.POST_CODE
			const prodList = this.checkedAll
				? this.allSameItems.scheduleInfos[index].prodList
				: this.deliveryInfos[index].prodh
			const channelType = this.checkedAll
				? this.allSameItems.scheduleInfos[index].isFuneralComine
					? 'FUNERAL'
					: ''
				: this.deliveryInfos[index].isFuneralComine
				? 'FUNERAL'
				: ''
			const paymentType = this.checkedAll
				? this.allSameItems.scheduleInfos[index].paymentType
				: this.deliveryInfos[index].paymentType
			const promotionCode = this.checkedAll
				? this.allSameItems.scheduleInfos[index].promotionCode
				: this.deliveryInfos[index].promotionCode
			const electricRangeType = this.checkedAll
				? this.allSameItems.scheduleInfos[index].electricRangeType
				: this.deliveryInfos[index].electricRangeType

			const scheduleInfo = {
				index,
				userId: this.$store.getters['common/codyNo'],
				zipSeq,
				workerType,
				goodsCodeList,
				address: reqAddress,
				goodsNameList: goodsNameList,
				zipCode: zipCode,
				orderList: '',
				prodList: prodList,
				channelType,
				paymentType: paymentType,
				promotionCode: promotionCode,
				electricRangeType: electricRangeType
			}

			this.$log('scheduleInfo param : ' + JSON.stringify(scheduleInfo))

			sessionStorage.setItem('schedule', JSON.stringify(scheduleInfo))

			const routerData = this.$router.resolve({
				name: 'order-schedule'
			})
			window.open(routerData.href, '_blank')
		},
		open() {
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
				this.monChk = true
			})
		},
		accept() {
			this.monChk = false
			this.resolve()
		},
		setupUserRelationName(code) {
			let res = ''
			switch (code) {
				case '0000':
					res = '본인'
					break
				case '0001':
					res = '가족'
					break
				case '0004':
					res = '친인척'
					break
				case '0005':
					res = '기타'
					break
				default:
					break
			}
			return res
		},
		displayOption(options) {
			const optionList = []

			if (options.mngType) {
				optionList.push({ name: '관리유형', value: options.mngType.text })
			}

			if (options.membershipType) {
				if (options.membershipType.code === '') {
					optionList.push({ name: '멤버십', value: '추가 안함' })
				} else {
					optionList.push({
						name: '멤버십',
						value: options.membershipType.text
					})
				}
			}

			if (options.filterType) {
				optionList.push({ name: '필터선택1', value: options.filterType.text })
			}

			if (options.filterType2) {
				optionList.push({ name: '필터선택2', value: options.filterType2.text })
			}

			if (options.filterType3) {
				optionList.push({ name: '필터선택3', value: options.filterType3.text })
			}

			if (options.filterType4) {
				optionList.push({ name: '필터선택4', value: options.filterType4.text })
			}

			if (options.hangerType) {
				optionList.push({ name: '추가선택1', value: options.hangerType.text })
			}

			if (options.hangerType2) {
				optionList.push({ name: '추가선택2', value: options.hangerType2.text })
			}

			if (options.setupType) {
				optionList.push({ name: '설치방식', value: options.setupType.text })
			}

			if (options.freeStand) {
				optionList.push({ name: '설치방식', value: options.freeStand.text })
			}

			if (options.prdOption2) {
				if (options.prdOption2.code === '') {
					optionList.push({ name: '별매상품', value: '추가 안함' })
				} else {
					const contract = this.displayPrdOption2Item(options.prdOption2)
						.contract
					const name = this.displayPrdOption2Item(options.prdOption2).name

					optionList.push({ name: '별매상품', value: contract + name })
				}
			}

			if (options.prdOption1) {
				if (options.prdOption1.code === '') {
					optionList.push({ name: '부가서비스', value: '추가 안함' })
				} else {
					optionList.push({
						name: '부가서비스',
						value: options.prdOption1.text
					})
				}
			}

			return optionList
		},

		// 별매상품 정보
		displayPrdOption2Item(item) {
			if (!item.code) return { name: '추가 안함' }
			const contract = item.CONTRACT_ID === '1' ? '일시불' : '렌탈'
			const price =
				item.CONTRACT_ID === '1'
					? item.GOODS_AMT === '0'
						? item.PRICE
						: item.GOODS_AMT
					: item.MONTH_AMT
			const div = item.CONTRACT_ID === '1' ? '+' : '월 +'
			const optionItem = {
				contract: `[${contract}]`,
				price: `${div} ${this.$options.filters.comma(price)}원`,
				name: item.text
			}
			return optionItem
		},
		async getAddrList() {
			let params = { kunnr: this.$store.state['verify'].customer.custNo }
			const res = await fetchAddrList(params)
			let resAddrList = res.resultObject.data.T_SETUP_LIST.filter(
				x => x.ZIP_SEQ !== '00000000'
			)
			await this.$store.dispatch('inst/setAddrList', resAddrList)
			this.addrList = resAddrList
		},

		async getMemoList() {
			const custNo = this.$store.state['verify'].customer.custNo
			if (this.isParameterEmpty(custNo)) return
			let params = { kunnr: custNo }
			const res = await fetchMemoList(params)
			let addMemos = res.resultObject.data.T_CUST_REQ_DESC.map(d => {
				return {
					code: d.ORDER_NO,
					text: d.CUST_REQ_DESC,
					...d
				}
			})

			addMemos.forEach(memo => {
				this.setupMemoItems.push(memo)
			})

			await this.$store.dispatch('inst/setMemoList', this.setupMemoItems)
		},

		openEnvInfo(index, pay) {
			const disusePrdList = []
			const preMemList = []
			const otherPreMemList = []
			const setupInfos = this.checkedAll
				? this.allSameItems.setupInfos
				: this.setupInfos
			setupInfos.forEach((v, i) => {
				if (index !== i && v.envInfo !== undefined) {
					disusePrdList.push({
						...v.envInfo.disusePrd
					})
					preMemList.push({
						...v.envInfo.preMem
					})
					otherPreMemList.push({
						...v.envInfo.otherPreMem
					})
				}
			})

			const refsName = this.checkedAll ? 'allSameEnvInfo' : 'envInfo'
			const prdList = this.$store.getters['prd/prdList']

			prdList.forEach(x => {
				setupInfos.forEach((y, i) => {
					if (x.E_INST_X === 'X') {
						if (x.prd.prod_nm === y.prdName) {
							setupInfos[i].instType = x.E_INST_X
						}
					}
				})
			})
			this.$refs[refsName + index][0].open(
				setupInfos[index].instType,
				setupInfos[index].envInfo,
				pay,
				disusePrdList,
				preMemList,
				otherPreMemList
			)
		},

		saveEnvInfo(obj) {
			this.$log(obj)
			if (this.checkedAll) {
				this.allSameItems.setupInfos[obj.no].envInfo = obj.envInfo
				this.allSameItems.hasEnvInfoArr[obj.no] = obj.isValid
				this.$set(this.allSameItems.hasEnvInfoArr, obj.no, obj.isValid)
			} else {
				this.setupInfos[obj.no].envInfo = obj.envInfo
				this.hasEnvInfoArr[obj.no] = obj.isValid
				this.$set(this.hasEnvInfoArr, obj.no, obj.isValid)
			}
		},

		handleSetupAreaTypeSelected(i, item) {
			if (this.checkedAll) {
				this.allSameItems.areaType = item
				this.allSameItems.areaTypeDisplayText = item.text
				this.allSameItems.setupAreaTypeCode = item.code
			} else {
				this.setupInfos[i].areaType = item
				this.setupInfos[i].areaTypeDisplayText = item.text
				this.setupAreaTypeCode[i] = item.code
			}
		},

		async next() {
			if (this.isParameterEmpty(this.mobOrderNo)) return
			// [BEGIN] 기존계정 상조 결합이 있을 경우 체크
			let funeralValidFailMsg = undefined
			if (this.isCowayLifeSolution) {
				const preFuneralProducts = this.$store.getters[
					'pay/funeralPayProducts'
				].filter(v => v.type === 'pre')

				for (let preFuneralProduct of preFuneralProducts) {
					const res = await fetchAidOrderCheck({
						I_ORDER_NO: preFuneralProduct?.preOrder?.ORDER_NO,
						I_STEP: 'A' //  'A' 기존주문 'B' 신규주문
					})
					const resObj = res.resultObject
					if (resObj.E_RETURN.TYPE !== 'T') {
						funeralValidFailMsg = resObj.E_RETURN.MESSAGE
						break
					}
				}
			}
			if (funeralValidFailMsg) {
				this.$alert({
					message: funeralValidFailMsg
				})
				return
			}
			// [END] 기존계정 상조 결합이 있을 경우 체크
			// [BEGIN] 다건동일 deliveryInfo 세팅 (사전계약은 Pass)
			if (this.checkedAll) {
				this.allSameItems.deliveryInfos = JSON.parse(
					JSON.stringify(this.deliveryInfos)
				)

				if (!this.isPreContract) {
					this.allSameItems.scheduleInfos.serviceManager.orders.forEach(
						order => {
							const deliveryInfo = this.allSameItems.deliveryInfos[
								order.seq - 1
							]
							deliveryInfo.bookingDate = this.allSameItems.scheduleInfos.serviceManager.bookingDate
							deliveryInfo.bookingSlot = this.allSameItems.scheduleInfos.serviceManager.bookingSlot
							deliveryInfo.bookingSlotTime = this.allSameItems.scheduleInfos.serviceManager.bookingSlotTime
							deliveryInfo.key = this.allSameItems.scheduleInfos.serviceManager.key
							deliveryInfo.address = this.allSameItems.scheduleInfos.serviceManager.address
							deliveryInfo.zipCode = this.allSameItems.scheduleInfos.serviceManager.zipCode
						}
					)

					this.allSameItems.scheduleInfos.delegate3PL.orders.forEach(order => {
						const deliveryInfo = this.allSameItems.deliveryInfos[order.seq - 1]
						deliveryInfo.bookingDate = this.allSameItems.scheduleInfos.delegate3PL.bookingDate
						deliveryInfo.bookingSlot = this.allSameItems.scheduleInfos.delegate3PL.bookingSlot
						deliveryInfo.bookingSlotTime = this.allSameItems.scheduleInfos.delegate3PL.bookingSlotTime
						deliveryInfo.key = this.allSameItems.scheduleInfos.delegate3PL.key
						deliveryInfo.address = this.allSameItems.scheduleInfos.delegate3PL.address
						deliveryInfo.zipCode = this.allSameItems.scheduleInfos.delegate3PL.zipCode
					})

					this.allSameItems.scheduleInfos.air3PL.orders.forEach(order => {
						const deliveryInfo = this.allSameItems.deliveryInfos[order.seq - 1]
						deliveryInfo.bookingDate = this.allSameItems.scheduleInfos.air3PL.bookingDate
						deliveryInfo.bookingSlot = this.allSameItems.scheduleInfos.air3PL.bookingSlot
						deliveryInfo.bookingSlotTime = this.allSameItems.scheduleInfos.air3PL.bookingSlotTime
						deliveryInfo.key = this.allSameItems.scheduleInfos.air3PL.key
						deliveryInfo.address = this.allSameItems.scheduleInfos.air3PL.address
						deliveryInfo.zipCode = this.allSameItems.scheduleInfos.air3PL.zipCode
					})
				}
			}
			// [END] 다건동일 deliveryInfo 세팅

			// [BEGIN] 사전계약 관리 판매인 체크
			const preContractSaleCheck = await this.preContractSaleChk()
			if (!preContractSaleCheck) return
			// [END] 사전계약 관리 판매인 체크

			// [BEGIN] 설치입력 정보 유효성 검사
			const validateResultMsg = await this.submitValidate()
			if (validateResultMsg.length > 0) {
				await this.$alert({
					message: validateResultMsg
				})
				return
			}

			if (this.checkedAll) {
				if (!this.$refs['commonInstForm'].validate()) {
					return false
				}
			} else {
				let count = 0
				let panelOpenCount = -1

				this.setupInfos.forEach((info, i) => {
					if (this.$refs['instForm'][i]) {
						if (!this.$refs['instForm'][i].validate()) {
							count++
							panelOpenCount = i
						}
					} else {
						count++
					}
				})

				if (count > 0) {
					if (panelOpenCount >= 0) {
						this.openedPanel = panelOpenCount
					}
					return false
				}
			}
			// [END] 설치입력 정보 유효성 검사

			// [BEGIN] 주문상태 유효성 검사
			const result = await mOrderInfoByEtc({
				id: this.mobOrderNo, // 웹주문번호
				statusCode: '107'
			})

			const msg = getMessageByStatusCode(result.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							type: this.$store.getters['common/type']
						}
					})
					.catch()

				return
			}
			// [END] 주문상태 유효성 검사

			// [BEGIN] 사전예약 취소여부 확인
			const customer = sessionStorage.getItem('customer')
			const customerJson = JSON.parse(customer)

			if (customerJson.orderType === 'S') {
				// 주문타입(S:사전예약, N:일반)
				for await (const pay of this.payList) {
					if (pay.options.preOrder) {
						const preOrderNo = pay.options.preOrder.ORDER_NO
						const preOrderSubStatusRes = await preOrderSubStatus(preOrderNo)

						const rtn = preOrderSubStatusRes.resultObject.E_RETURN
						const rtnOrder = preOrderSubStatusRes.resultObject.ES_ORDER

						// 사전예약 취소일 경우, 주문불가처리 후 주문현황으로 돌아감
						if (rtn.TYPE === 'T') {
							if (rtnOrder.SUBSTATUS !== 'S') {
								await this.$alert({
									message: '사전예약이 취소되어 주문 생성이 불가합니다.'
								})

								const result = await fetchWebOrderInfoById(this.mobOrderNo)

								let webParams = result.resultObject
								webParams.step = '10' // 주문취소
								webParams.statusCode = '201' // 주문불가

								let json = JSON.parse(webParams.orderInfoJson)
								const params = {}
								Object.assign(params, {
									message: '사전예약이 취소되어 주문 생성이 불가합니다.',
									rtnType: 'F',
									rtnCode: rtn.CODE,
									canProceedOrder: false
								})
								json = Object.assign(json, {
									inaccessMsg: params
								})
								webParams.orderInfoJson = JSON.stringify(json)

								await postWebOrderInfoById(this.mobOrderNo, webParams)

								sessionStorage.removeItem('customer')
								await this.$router.push({ name: 'order-status' })
								return
							}
						} else {
							await this.$alert({
								message: rtn.message
							})
							return
						}
					}
				}
			}
			// [END] 사전예약 취소여부 확인

			const altRes = await this.$alert({
				message:
					'확인을 누르면 주문 내용이 확정되고 더 이상 변경이 불가합니다.<br />계속 진행하시겠습니까?',
				okText: '확인',
				cancelText: '취소'
			})

			if (!altRes) {
				return
			}

			// 주문정보 생성
			try {
				const res = await fetchWebOrderInfoById(this.mobOrderNo)
				const webParams = res.resultObject
				const order = JSON.parse(webParams.orderInfoJson)

				const IT_PROD = []
				const IT_ACCEPT = []
				const IT_SETUP = []
				const IT_PAYMENT = []
				const IT_PACKCOMBI = []
				const excludedReturnOrders = []
				const codyNo = this.$store.getters['common/codyNo']
				const custNo = this.$store.getters['verify/custNo']
				const itPaymentArr = this.$store.getters['pay/paymentOrders']
				const funeralProducts = this.$store.getters['pay/funeralPayProducts']

				// [BEGIN] 결합요금 정보 생성
				const packTemp = this.$store.getters['pay/itPackcombi']
				packTemp.forEach(v => {
					IT_PACKCOMBI.push({
						ORDER_NO: v.ORDER_NO,
						PACKAGE_ID: v.PACKAGE_ID,
						COMBI_NO: this.isPreContract ? '' : v.COMBI_NO,
						COMBI_SEQ: this.isPreContract ? '' : v.COMBI_SEQ,
						COMBI_GB: this.isPreContract ? '' : v.COMBI_GB,
						DC_RATE: v.DC_RATE
					})
				})
				// [END] 결합요금 정보 생성

				this.payList.forEach((pay, index) => {
					const esProd = pay.esProd
					const agreeTerm = pay.options.agreeTerm // 의무사용기간(약정기간)
					const sellType = pay.options.sellType // 판매구분
					const prePass = pay.payOptions.orderType // 프리패스 여부
					const prdOption2 = pay.options.prdOption2 // 별매상품
					const hangerType = pay.options.hangerType // 행거타입
					const hangerType2 = pay.options.hangerType2 // 행거타입2
					const freeStand = pay.options.freeStand // 스탠드타입
					const filterType = pay.options.filterType // 필터타입
					const filterType2 = pay.options.filterType2 // 필터타입2
					const filterType3 = pay.options.filterType3 // 필터타입3
					const filterType4 = pay.options.filterType4 // 필터타입4
					const setupType = pay.options.setupType // 설치타입
					const creditStatus = pay.options.E_CREDIT_STATUS
					const preOrder = pay.options.preOrder // 기존주문번호
					const homePreOrder = pay.options.homePreOrder // 홈체험2주문번호
					const payOptions = pay.payOptions
					const placeId2 = pay.options.chairMultiFac // 다중이용시설 선택 정보

					// [BEGIN] 신규 상조 결합 상품 ORDER_SEQ 삽입
					funeralProducts.filter(funeralProduct => {
						if (funeralProduct.oid2 === pay.oid2) {
							funeralProduct.ORDER_SEQ = index + 1
						}
					})
					// [END] 신규 상조 결합 상품 ORDER_SEQ 삽입

					// [BEGIN] 결제 정보 생성
					// 미반납 재렌탈 프로모션 방어 코딩
					if (
						pay.payOptions.returnPromotionApplied &&
						itPaymentArr[index] &&
						itPaymentArr[index]?.PRM_CODE === '' &&
						itPaymentArr[index]?.MPRM_CODE === 'A067'
					) {
						itPaymentArr[index].PRM_CODE = 'A067'
					}

					delete itPaymentArr[index].isRentalZeroOrMinusAmtExist
					IT_PAYMENT.push(itPaymentArr[index])
					// [END] 결제 정보 생성

					// [BEGIN] 설치 정보 세팅
					let setupInfo
					let envInfo
					let hasEnvInfo
					let deliveryInfo

					if (this.checkedAll) {
						// 다건동일주문
						setupInfo = this.allSameItems
						envInfo = this.allSameItems.setupInfos[index].envInfo
						hasEnvInfo = this.allSameItems.hasEnvInfoArr[index]
						deliveryInfo = this.allSameItems.deliveryInfos[index]
					} else {
						setupInfo = this.setupInfos[index]
						envInfo = this.setupInfos[index].envInfo
						hasEnvInfo = this.hasEnvInfoArr[index]
						deliveryInfo = this.deliveryInfos[index]
					}
					// [END] 설치 정보 세팅

					// [BEGIN] 부상품 정보 생성
					if (filterType && filterType.code) {
						IT_PROD.push({
							ORDER_SEQ: index + 1,
							GOODS_CD: filterType.MATNR,
							ORDER_QTY: filterType.MAT_QTY,
							PRICE: '0',
							REGI_GRADE: '0',
							GOODS_ID: '002',
							CONTRACT_ID: esProd.CONTRACT_ID
						})
					}

					if (filterType2 && filterType2.code) {
						IT_PROD.push({
							ORDER_SEQ: index + 1,
							GOODS_CD: filterType2.MATNR,
							ORDER_QTY: '1',
							PRICE: '0',
							REGI_GRADE: '0',
							GOODS_ID: '002',
							CONTRACT_ID: esProd.CONTRACT_ID
						})
					}

					if (filterType3 && filterType3.code) {
						IT_PROD.push({
							ORDER_SEQ: index + 1,
							GOODS_CD: filterType3.MATNR,
							ORDER_QTY: '1',
							PRICE: '0',
							REGI_GRADE: '0',
							GOODS_ID: '002',
							CONTRACT_ID: esProd.CONTRACT_ID
						})
					}

					if (filterType4 && filterType4.code) {
						IT_PROD.push({
							ORDER_SEQ: index + 1,
							GOODS_CD: filterType4.MATNR,
							ORDER_QTY: '1',
							PRICE: '0',
							REGI_GRADE: '0',
							GOODS_ID: '002',
							CONTRACT_ID: esProd.CONTRACT_ID
						})
					}

					if (hangerType && hangerType.code) {
						if (hangerType.MATNR.indexOf('/') > -1) {
							let mArr = hangerType.MATNR.split('/')
							for (let m = 0; m < mArr.length; m++) {
								IT_PROD.push({
									ORDER_SEQ: index + 1,
									GOODS_CD: mArr[m],
									ORDER_QTY: '1',
									PRICE: '0',
									REGI_GRADE: '0',
									GOODS_ID: '002',
									CONTRACT_ID: esProd.CONTRACT_ID
								})
							}
						} else {
							IT_PROD.push({
								ORDER_SEQ: index + 1,
								GOODS_CD: hangerType.MATNR,
								ORDER_QTY: '1',
								PRICE: '0',
								REGI_GRADE: '0',
								GOODS_ID: '002',
								CONTRACT_ID: esProd.CONTRACT_ID
							})
						}
					}

					if (hangerType2 && hangerType2.code) {
						IT_PROD.push({
							ORDER_SEQ: index + 1,
							GOODS_CD: hangerType2.MATNR,
							ORDER_QTY: '1',
							PRICE: '0',
							REGI_GRADE: '0',
							GOODS_ID: '002',
							CONTRACT_ID: esProd.CONTRACT_ID
						})
					}

					if (freeStand && freeStand.code) {
						IT_PROD.push({
							ORDER_SEQ: index + 1,
							GOODS_CD: freeStand.MATNR,
							ORDER_QTY: '1',
							PRICE: '0',
							REGI_GRADE: '0',
							GOODS_ID: '002',
							CONTRACT_ID: esProd.CONTRACT_ID
						})
					}

					if (prdOption2 && prdOption2.code) {
						IT_PROD.push({
							ORDER_SEQ: index + 1,
							GOODS_CD: prdOption2.GOODS_CD,
							PRICE:
								prdOption2.GOODS_AMT === '0'
									? prdOption2.PRICE
									: prdOption2.GOODS_AMT,
							SALE_PERCENT: prdOption2.SALE_PERCENT,
							CONTRACT_ID: prdOption2.CONTRACT_ID,
							GOODS_ID: prdOption2.GOODS_ID,
							REGI_GRADE: prdOption2.REGI_GRADE,
							ORDER_QTY: 1,
							MONTH_AMT: prdOption2.MONTH_AMT
						})
					}
					// [END] 부상품 정보 생성

					const placeId = this.checkedAll
						? this.allSameItems.areaType.code
						: this.setupInfos[index].areaType.code

					// [BEGIN] 주상품 정보 생성
					const itProd = {
						GOODS_CD: esProd.GOODS_CD,
						GOODS_NM: esProd.GOODS_NM,
						PRICE: esProd.PRICE,
						MONTH_AMT: esProd.MONTH_AMT,
						ORDER_QTY: 1,
						CONTRACT_ID: esProd.CONTRACT_ID,
						GOODS_ID: esProd.GOODS_ID,
						USE_TP: esProd.USE_TP,
						REGI_ID: esProd.REGI_ID,
						USE_DUTY_MONTH: esProd.USE_DUTY_MONTH,
						LESGB: esProd.LESGB,
						PLACE_ID: placeId,
						ONCE_GRADE1: esProd.ONCE_GRADE1,
						ONCE_GRADE2: esProd.ONCE_GRADE2,
						ONCE_GRADE3: esProd.ONCE_GRADE3,
						ORDER_SEQ: index + 1,
						REGI_GRADE: esProd.REGI_GRADE,
						FEE_START_NTH: esProd.FEE_START_NTH
					}

					if (agreeTerm) {
						itProd.SALE_ID = agreeTerm.SALE_ID
						itProd.RENTAL_YEARS = agreeTerm.OWNER
					}

					if (sellType.ORD_TYPE === 'Z001') {
						// 렌탈
						if (['P', 'PK', 'FP', 'FT'].includes(prePass)) {
							itProd.SALE_ID = '0043'
							itProd.DC_AMT = '100000'
						} else {
							itProd.SALE_ID = '0001'
						}
					} else if (sellType.ORD_TYPE === 'Z002') {
						// 재렌탈
						if (agreeTerm) {
							itProd.DC_AMT = '100000'
						}
						itProd.SALE_ID = esProd.SALE_ID
					} else if (sellType.ORD_TYPE === 'Z003') {
						// 일시불
						itProd.SALE_ID = esProd.SALE_ID
					} else {
						// Z004(자체할부), Z005(보상판매)
						itProd.SALE_ID = esProd.SALE_ID
					}

					// 자체할부 일시불 할인 가격
					if (sellType.ORD_TYPE === 'Z004' && pay.payOptions.ET_ZSDS1518) {
						itProd.DC_AMT = pay.payOptions.ET_ZSDS1518[0].DC_AMT || ''
					}

					if (preOrder && preOrder.ORDER_NO) {
						itProd.PRE_ORDER_NO = preOrder.ORDER_NO
						if (itProd.GOODS_ID === '001' && preOrder.RE_SALE_ID) {
							itProd.SALE_ID = preOrder.RE_SALE_ID
						}
					}

					if (homePreOrder && homePreOrder.HOME2_ORDER_NO) {
						itProd.HOME2_ORDER_NO = homePreOrder.HOME2_ORDER_NO
					}

					if (placeId2 && placeId2.code) {
						itProd.PLACE_ID2 = placeId2.code
					}

					// 추천 판매인 사번
					if (this.checkedAll && this.allSameItems.recommnedSales.PERNR) {
						// 005111 제품은 의료기기 판매업 신고된 조직의 판매인만 적용가능
						if (esProd?.PRDHA?.indexOf('005111') === 0) {
							itProd.ZRECOMSALES =
								this.allSameItems.recommnedSales.MEDICAL_YN === 'Y'
									? this.allSameItems.recommnedSales.PERNR
									: ''
						} else {
							itProd.ZRECOMSALES = this.allSameItems.recommnedSales.PERNR
						}
					} else if (this.setupInfos[index].recommnedSales.PERNR) {
						// 005111 제품은 의료기기 판매업 신고된 조직의 판매인만 적용가능
						if (esProd?.PRDHA?.indexOf('005111') === 0) {
							itProd.ZRECOMSALES =
								this.setupInfos[index].recommnedSales.MEDICAL_YN === 'Y'
									? this.setupInfos[index].recommnedSales.PERNR
									: ''
						} else {
							itProd.ZRECOMSALES = this.setupInfos[index].recommnedSales.PERNR
						}
					}

					if (
						payOptions.downRentalPrepayAmt &&
						payOptions.downRentalPrepayAmt !== '0'
					) {
						itProd.PREGB = payOptions.preGb
						itProd.PRE_AMT_2 = payOptions.downRentalPrepayAmt
						itProd.PRE_AMT_KEY = payOptions.preAmtKey
					}

					if (hasEnvInfo && envInfo && envInfo?.otherPreMem) {
						itProd.OTHER_ORD_NO = envInfo.otherPreMem.ORDER_NO || ''
					}

					// 상조 결합 상품인 경우
					let aidType = ''
					funeralProducts.forEach(v => {
						if (v.type === 'new' && v.oid2 === pay.oid2) {
							if (v.prd.prodCd === '499') {
								aidType = aidType.concat('A')
							} else if (v.prd.prodCd === '599') {
								aidType = aidType.concat('B')
							}
						}
					})

					if (aidType.length > 0) {
						itProd.AID_TYPE = aidType
					}

					// 기존계정에 상조가 결합되어 있다면 R 코드로 전달
					if (preOrder && preOrder.AID_TYPE && preOrder.AID_TYPE !== '') {
						itProd.AID_TYPE = 'R'
					}

					// 상조계약 의사 표현 여부
					if (pay.options?.cowayLifeCombineAbleApply) {
						itProd.AID_REQ = 'Y'
					}

					IT_PROD.push(itProd)
					// [END] 주상품 정보 생성

					// [BEGIN] 수납 정보 생성
					if (order.recp && order.recp.recpInfos) {
						// 카드결제
						if (
							order.recp.recpInfos.mthd === 'BB' ||
							order.recp.recpInfos.mthd === '02' ||
							order.recp.recpInfos.mthd === '12'
						) {
							const tcObj = order.recp.recpCardResult
							this.$log('tcObj ===>>>', tcObj)

							// 1. 상품 수납금, CONTRACT_ID : 1, RECP_AMT2
							this.$log('상품 수납금', payOptions.RECP_AMT2)

							// 2. 등록비, INS_MONTHS
							// A : 일시불, L : 분납
							// 등록비 면제 : orderType P, PK, MP
							this.$log('등록비 납부방법', payOptions.regFeePayMethod)
							this.$log('등록비 자체할부 개월수', payOptions.installmentMonth)

							// 3. 렌탈료 선납금, payOptions.prepayAmt
							this.$log('렌탈료 선납금', payOptions.prepayAmt)

							// 4. 멤버십 선납금, msPreAmt
							this.$log('멤버십 선납', payOptions.msPreAmt)

							// 5. 유상 별매 상품, prdOption2 && prdOption2.code
							this.$log('유샹별매 상품', prdOption2)

							if (tcObj) {
								let itCard = {}
								itCard.KUNNR = custNo // 고객번호
								itCard.RECP_TP = this.getRecpTp(pay, payOptions)
								itCard.RECP_AMT2 = Number(payOptions.RECP_AMT2)
								itCard.REGI_GRADE = esProd.REGI_GRADE
								itCard.SALE_ID = itProd.SALE_ID
								itCard.RECP_NO = codyNo // 코디번호
								itCard.USER_NO = codyNo // 코디번호
								itCard.ORDER_SEQ = index + 1
								itCard.ORDER_NO = index + 1
								itCard.JOIND = '1' // 고정값
								itCard.CARDN = tcObj.I_CARDN // 카드번호
								this.$log('카드 수납 정보1', pay, itCard)

								//렌탈 선납
								if (
									payOptions.prepayAmt &&
									payOptions.prepayAmt !== 0 &&
									payOptions.prepayAmt !== undefined
								) {
									let itPre = {}
									itPre.KUNNR = custNo // 고객번호
									itPre.RECP_TP = pay.esProd.LESGB === 'L' ? '72' : '12'
									itPre.RECP_AMT2 = Number(payOptions.prepayAmt)
									itPre.GOAL_AMT2 = Number(payOptions.prepayGoalAmt)
									itPre.DC_RATE = payOptions.prepayDcRate
									itPre.REGI_GRADE = esProd.REGI_GRADE
									itPre.SALE_ID = itProd.SALE_ID
									itPre.RECP_NO = codyNo // 코디번호
									itPre.USER_NO = codyNo // 코디번호
									itPre.JOIND = '1' // 고정값
									itPre.CARDN = tcObj.I_CARDN // 카드번호
									itPre.ORDER_SEQ = index + 1
									itPre.ORDER_NO = index + 1
									IT_ACCEPT.push(itPre)
									this.$log(
										'카드 수납 정보, 렌탈료 선납',
										pay.esProd.LESGB,
										itPre
									)
									itCard.RECP_AMT2 = Number(
										itCard.RECP_AMT2 - payOptions.prepayAmt
									)
								}

								// 선납할인2
								if (payOptions.downRentalPrepayAmt !== '0') {
									let itPre = {}
									itPre.KUNNR = custNo // 고객번호
									itPre.RECP_TP = 'D1'
									itPre.RECP_AMT2 = Number(payOptions.downRentalPrepayAmt)
									itPre.REGI_GRADE = esProd.REGI_GRADE
									itPre.SALE_ID = itProd.SALE_ID
									itPre.RECP_NO = codyNo // 코디번호
									itPre.USER_NO = codyNo // 코디번호
									itPre.JOIND = '1' // 고정값
									itPre.CARDN = tcObj.I_CARDN // 카드번호
									itPre.ORDER_SEQ = index + 1
									itPre.ORDER_NO = index + 1
									IT_ACCEPT.push(itPre)
									this.$log(
										'카드 수납 정보, 선납할인2 선납',
										pay.esProd.LESGB,
										itPre
									)
									itCard.RECP_AMT2 = Number(
										itCard.RECP_AMT2 - payOptions.downRentalPrepayAmt
									)
								}

								//일시불 멤버십 선납
								if (
									payOptions.msPreAmt &&
									payOptions.msPreAmt !== 0 &&
									payOptions.msPreAmt !== undefined
								) {
									let itPre = {}
									itPre.KUNNR = custNo // 고객번호
									itPre.RECP_TP = '22'
									itPre.RECP_AMT2 = Number(payOptions.msPreAmt)
									itPre.REGI_GRADE = esProd.REGI_GRADE
									itPre.SALE_ID = itProd.SALE_ID
									itPre.RECP_NO = codyNo // 코디번호
									itPre.USER_NO = codyNo // 코디번호
									itPre.JOIND = '1' // 고정값
									itPre.CARDN = tcObj.I_CARDN // 카드번호
									itPre.ORDER_SEQ = index + 1
									itPre.ORDER_NO = index + 1
									itPre.GOAL_AMT2 = Number(payOptions.msGoalAmt)
									itPre.PRE_MONTH = Number(payOptions.msPreMonth)
									IT_ACCEPT.push(itPre)
									this.$log(
										'카드 수납 정보, 일시불 멤버십 선납',
										pay.esProd.LESGB,
										itPre
									)
									itCard.RECP_AMT2 = Number(
										itCard.RECP_AMT2 - payOptions.msPreAmt
									)
								}

								//유상 별매상품있을시 추가
								if (prdOption2 && prdOption2.code) {
									let option = {
										KUNNR: custNo, // 고객번호
										RECP_TP: '01',
										RECP_AMT2:
											prdOption2.GOODS_AMT === '0'
												? prdOption2.PRICE
												: prdOption2.GOODS_AMT,
										REGI_GRADE: esProd.REGI_GRADE,
										SALE_ID: itProd.SALE_ID,
										RECP_NO: codyNo,
										USER_NO: codyNo,
										ORDER_SEQ: index + 1,
										ORDER_NO: index + 1,
										JOIND: '1', // 고정값
										CARDN: tcObj.I_CARDN // 카드번호
									}
									IT_ACCEPT.push(option)
									this.$log(
										'카드 수납 정보, 유상별매상품',
										pay.esProd.LESGB,
										option
									)
									itCard.RECP_AMT2 = Number(itCard.RECP_AMT2 - option.RECP_AMT2)
								}
								IT_ACCEPT.push(itCard)
								this.$log('카드 수납 정보2', pay.esProd.LESGB, itCard)
							}
						}

						// 은행이체
						else if (
							order.recp.recpInfos.mthd === 'AA' ||
							order.recp.recpInfos.mthd === '11'
						) {
							let itAccount = {}
							itAccount.KUNNR = custNo // 고객번호
							itAccount.RECP_TP = this.getRecpTp(pay, payOptions)
							itAccount.RECP_AMT2 = Number(payOptions.RECP_AMT2)
							itAccount.REGI_GRADE = esProd.REGI_GRADE
							itAccount.SALE_ID = itProd.SALE_ID
							itAccount.RECP_NO = codyNo // 코디번호
							itAccount.USER_NO = codyNo // 코디번호
							itAccount.ORDER_SEQ = index + 1
							itAccount.ORDER_NO = index + 1

							//렌탈 선납
							if (
								payOptions.prepayAmt &&
								payOptions.prepayAmt !== 0 &&
								payOptions.prepayAmt !== undefined
							) {
								let itPre = {}
								itPre.KUNNR = custNo // 고객번호
								itPre.RECP_TP = pay.esProd.LESGB === 'L' ? '72' : '12'
								itPre.RECP_AMT2 = Number(payOptions.prepayAmt)
								itPre.GOAL_AMT2 = Number(payOptions.prepayGoalAmt)
								itPre.DC_RATE = payOptions.prepayDcRate
								itPre.REGI_GRADE = esProd.REGI_GRADE
								itPre.SALE_ID = itProd.SALE_ID
								itPre.RECP_NO = codyNo // 코디번호
								itPre.USER_NO = codyNo // 코디번호
								itPre.ORDER_SEQ = index + 1
								itPre.ORDER_NO = index + 1
								IT_ACCEPT.push(itPre)
								itAccount.RECP_AMT2 = Number(
									itAccount.RECP_AMT2 - payOptions.prepayAmt
								)
							}

							// 선납할인2
							if (payOptions.downRentalPrepayAmt !== '0') {
								let itPre = {}
								itPre.KUNNR = custNo // 고객번호
								itPre.RECP_TP = 'D1'
								itPre.RECP_AMT2 = Number(payOptions.downRentalPrepayAmt)
								itPre.REGI_GRADE = esProd.REGI_GRADE
								itPre.SALE_ID = itProd.SALE_ID
								itPre.RECP_NO = codyNo // 코디번호
								itPre.USER_NO = codyNo // 코디번호
								itPre.ORDER_SEQ = index + 1
								itPre.ORDER_NO = index + 1
								IT_ACCEPT.push(itPre)
								itAccount.RECP_AMT2 = Number(
									itAccount.RECP_AMT2 - payOptions.downRentalPrepayAmt
								)
							}

							//일시불 멤버십 선납
							else if (
								payOptions.msPreAmt &&
								payOptions.msPreAmt !== 0 &&
								payOptions.msPreAmt !== undefined
							) {
								let itPre = {}
								itPre.KUNNR = custNo // 고객번호
								itPre.RECP_TP = '22'
								itPre.RECP_AMT2 = Number(payOptions.msPreAmt)
								itPre.REGI_GRADE = esProd.REGI_GRADE
								itPre.SALE_ID = itProd.SALE_ID
								itPre.RECP_NO = codyNo // 코디번호
								itPre.USER_NO = codyNo // 코디번호
								itPre.ORDER_SEQ = index + 1
								itPre.ORDER_NO = index + 1
								itPre.GOAL_AMT2 = Number(payOptions.msGoalAmt)
								itPre.PRE_MONTH = Number(payOptions.msPreMonth)
								IT_ACCEPT.push(itPre)
								itAccount.RECP_AMT2 = Number(
									itAccount.RECP_AMT2 - payOptions.msPreAmt
								)
							}

							//유상 별매상품있을시 추가
							if (prdOption2 && prdOption2.code) {
								let option = {
									KUNNR: custNo, // 고객번호
									RECP_TP: '01',
									RECP_AMT2:
										prdOption2.GOODS_AMT === '0'
											? prdOption2.PRICE
											: prdOption2.GOODS_AMT,
									REGI_GRADE: esProd.REGI_GRADE,
									SALE_ID: itProd.SALE_ID,
									RECP_NO: codyNo,
									USER_NO: codyNo,
									ORDER_SEQ: index + 1,
									ORDER_NO: index + 1
								}
								IT_ACCEPT.push(option)
								itAccount.RECP_AMT2 = Number(
									itAccount.RECP_AMT2 - option.RECP_AMT2
								)
							}
							IT_ACCEPT.push(itAccount)
						}
					}
					// [END] 수납 정보 생성

					const itSetup = {}
					itSetup.USE_CUST_ID = custNo // 고객번호
					itSetup.GOODS_CD = esProd.GOODS_CD // 상품코드
					itSetup.CONTRACT_CUST_ID = custNo // 고객번호
					itSetup.CUST_NM = setupInfo.name
					itSetup.POST_CODE =
						setupInfo.addr && setupInfo.addr.zonecode
							? setupInfo.addr.zonecode
							: setupInfo.address.POST_CODE
					itSetup.ADDR1 =
						setupInfo.addr && setupInfo.addr.roadAddress
							? setupInfo.addr.roadAddress
							: setupInfo.address.ADDR1
					itSetup.ADDR2 =
						setupInfo.addr && setupInfo.addr.detailAddress
							? setupInfo.addr.detailAddress
							: setupInfo.address.ADDR2
					itSetup.ZIP_SEQ =
						setupInfo.addr && setupInfo.addr.zipSeq
							? setupInfo.addr.zipSeq
							: setupInfo.address.ZIP_SEQ
					itSetup.TEL_MOBILE = setupInfo.phone
					itSetup.TEL_NUMBER = setupInfo.setupTelNumber
					itSetup.CREDIT_STATUS = creditStatus

					if (setupType) {
						itSetup.ELEC_RANGE_BLT = setupType.code
					}
					if (freeStand) {
						itSetup.ELEC_RANGE_BLT = 'F'
					}

					// 추가선택이 행거-서비스아세이(AP-1519A)인 경우, ELEC_RANGE_BLT은 F로 고정
					if (
						(hangerType && hangerType.MATNR === '3105723') ||
						(hangerType2 && hangerType2.MATNR === '3105723')
					) {
						itSetup.ELEC_RANGE_BLT = 'F'
					}

					if (deliveryInfo.workerType !== 'EXPRESS') {
						let planDt = this.isPreContract
							? moment(deliveryInfo.preOwnerYm.concat('01')).format(
									'YYYY-MM-DD'
							  )
							: moment(deliveryInfo.bookingDate).format('YYYY-MM-DD')
						itSetup.PLAN_DT = planDt
						itSetup.CUST_REQ_DESC = setupInfo.memo // 설치메모 (128자)
					} else {
						itSetup.CUST_REQ_DESC = setupInfo.deliveryMemo // 택배메모 (20자)
					}

					// 주소정제 RFC 변경되면서 주소 정제 체크 값 Y 전달로 수정
					itSetup.KUNNR_ADDR_CHK = 'Y'

					//region 설치환경정보 생성
					if (hasEnvInfo && envInfo) {
						if (envInfo.otherPrd) {
							itSetup.DJ_CODE = envInfo.otherPrd.code // 타사제품정보
						}
						if (envInfo.multipleFac) {
							itSetup.DJ_CODE = envInfo.multipleFac.code // 다중시설
						}
						if (pay.payOptions.ET_ZSDS5204) {
							pay.payOptions.ET_ZSDS5204.forEach(x => {
								if (
									pay.payOptions.promotion &&
									x &&
									pay.payOptions.promotion === x.PRM_CODE &&
									(x.OTHER_RTN_YN === 'Z' || x.OTHER_RTN_YN === 'Y')
								) {
									if (envInfo.manuFac) {
										itSetup.MANUF = envInfo.manuFac.code // 제조사
									}
									if (envInfo.makeDateYear) {
										itSetup.YEARS_21 = envInfo.makeDateYear.text // 설치/제조 년
									}
									if (envInfo.makeDateMonth) {
										itSetup.MONTH = envInfo.makeDateMonth.code // 설치/제조 월
									}
									if (envInfo.prdForm) {
										itSetup.PROD_F = envInfo.prdForm.code // 제품형태
									}
									if (envInfo.prdType) {
										itSetup.PROD_TY = envInfo.prdType.code // 제품종류
									}
									if (envInfo.instForm) {
										itSetup.INST_F = envInfo.instForm.code // 설치형태
									}
									if (envInfo.number) {
										itSetup.MARKNUM = envInfo.number // 물마크번호
									}
									if (envInfo.caNum) {
										itSetup.CANUM = envInfo.caNum // CA 번호
									}
								}
							})
						}

						if (envInfo.waterQuality) {
							if (this.isWaterPurifier(pay.options.prdha)) {
								itSetup.WATER_QLTY = envInfo.waterQuality.code // 정수기 수질 (01:상수도, 02:지하수)
							} else {
								itSetup.WATER_QLTY2 = envInfo.waterQuality.code // 연수기 수질 (01:상수도, 02:지하수)
							}
						}
						if (envInfo.waterDistance) {
							itSetup.WATERWORKS = envInfo.waterDistance.code // 수도와의거리 (01:15m이내, 02:15m이상)
						}
						if (envInfo.machineConnect) {
							itSetup.CMCONNECTION_YN = envInfo.machineConnect.code
						}
						if (envInfo.thinkX) {
							itSetup.THINK_X = envInfo.thinkX.code === 'Y' ? 'X' : ''
						}
						if (envInfo.think2X) {
							itSetup.THINK2_X = envInfo.think2X.code === 'Y' ? 'X' : ''
						}
						if (envInfo.wallType) {
							if (envInfo.wallType.code === '1') {
								itSetup.SOFT_STAT_1 = 'Y' // 벽 종류 (플라스틱)
							} else {
								itSetup.SOFT_STAT_4 = 'Y' // 벽 종류 (일반)
							}
						}
						if (envInfo.showerHeadType) {
							if (envInfo.showerHeadType.code === '2') {
								itSetup.SOFT_STAT_2 = 'Y' // 샤워기 종류 (해바라기)
							} else {
								itSetup.SOFT_STAT_3 = 'Y' // 샤워기 종류 (전신)
							}
						}
						if (envInfo.playVideoYn) {
							itSetup.ZWIFI_ERR_YN = envInfo.playVideoYn.code
							//욕실 내 Wi-Fi 인터넷 재생 오류 (Y:있음, initial:없음)
						}
						if (envInfo.wifiYn) {
							itSetup.ZWIFI_OWN_YN = envInfo.wifiYn.code
							//Wi-Fi 공유기 보유여부 (Y:있음, initial:없음)
							//비데: WiFi 공유기를 보유하고 있으며 화장실 내 WiFi로 동영상 재생이 어려운 경우
							if (
								envInfo.wifiYn.code === 'Y' &&
								envInfo.playVideoYn.code === 'Y'
							) {
								itSetup.ELEC_RANGE_BLT = 'F'
							}
						}
						if (envInfo.socketYn) {
							itSetup.SOCKET = envInfo.socketYn.code // 전기콘센트 유무 (01:있음(220V), 02:있음(110V), 03:없음)
						}
						if (envInfo.socketLoc) {
							itSetup.SOCKET_POSI = envInfo.socketLoc.code // 전기콘센트 위치 (01:변기옆/뒤, 02:변기건너편, 03:욕실외부)
						}
						if (envInfo.visit) {
							itSetup.ZMF_YN = envInfo.visit.code // 방문일자변경 (Y/N)
						}
						if (envInfo.waste) {
							itSetup.DISUSE = envInfo.waste.code // 폐기제품내림 (01:자사제품, 02:타사제품, 03:없음)
						}
						if (envInfo.elevator) {
							const goodsCd = pay.esProd.GOODS_CD.replace(/(^0+)/, '')
							if (
								['4100398', '4100399', '4100500', '4100498'].includes(goodsCd)
							) {
								itSetup.ELEVATOR2 = envInfo.elevator.code // 엘리베이터사용 (01:17인이상,02:미만,03:없음,04:확인필요)
							} else {
								itSetup.ELEVATOR = envInfo.elevator.code // 엘리베이터사용 (01:10인승이상, 02:10인승이하)
							}
						}
						if (envInfo.ladderCar) {
							itSetup.LADDER = envInfo.ladderCar.code // 사다리차필요 (01:필요, 02:불필요)
						}
						if (envInfo.returnOther) {
							itSetup.OTHER_RTN_YN = envInfo.returnOther.code // 타사제품반환여부 (Y:자사제품, N:타사제품, SPACE:미반환)
						}
						if (envInfo.installType) {
							itSetup.INST_X = envInfo.installType.code === '01' ? 'X' : '' // 설치타입 (01:단순배송, 02:방문설치)
						}
						if (envInfo.memberships) {
							itSetup.SC_MAT_ORDER = envInfo.memberships.ORDER_NO // 폐기 매트리스 주문번호
						}
						if (envInfo.windowWidth) {
							// 진입 창문 개방시 너비 (01:폭 2M이상, 02:폭 2M미만, 03:창문으로 진입 불가, initial)
							itSetup.WINDOW_WIDTH = envInfo.windowWidth.code
						}
						if (envInfo.doorSlide) {
							//설치장소 문 여닫힘 확인 (01:이상없음, 02:공간부족, initial)
							itSetup.INST_DOOR = envInfo.doorSlide.code
						}
						if (envInfo.wardrobe) {
							//붙박이장 여닫힘 확인  (01:이상없음, 02:공간부족, initial)
							itSetup.IN_DOOR = envInfo.wardrobe.code
						}
						if (envInfo.lkMat) {
							//자사매트리스와 타사프레임 조합 이상 유무 (01:이상없음, 02:프레임 사이즈 작음, initial)
							itSetup.COMB_M = envInfo.lkMat.code
						}
						if (envInfo.lkFrame) {
							//자사프레임과 타사매트리스 조합 이상 유무 (01:이상없음, 02:매트리스 사이즈 안맞음, initial)
							itSetup.COMB_F = envInfo.lkFrame.code
						}
						if (envInfo.disusePrd) {
							//폐기 매트리스 주문번호
							itSetup.SC_MAT_ORDER = envInfo.disusePrd.ORDER_NO
						}
						if (envInfo.preMem) {
							//기존 멤버십 해지 주문번호
							itSetup.MORDER_NO = envInfo.preMem.ORDER_NO
						}
						if (envInfo.addServiceType) {
							// 추가내림 서비스
							itSetup.NAELIM = envInfo.addServiceType.code
						}
						if (envInfo.moveServiceType) {
							// 이동 서비스
							itSetup.MOVES = envInfo.moveServiceType.code
						}
					}
					//endregion

					if (itProd.CONTRACT_ID === '1') {
						//일시불이면
						itSetup.MEM_YN = 'N'
						itSetup.MEM_USE_TP = ''
						itSetup.MEM_MONTH_AMT = ''
						if (payOptions.membership && payOptions.membership !== '00') {
							//멤버십 가입이면
							itSetup.MEM_YN = 'Y'
							itSetup.MEM_USE_TP = payOptions.memUseTp
							itSetup.MEM_MONTH_AMT = payOptions.msMonthAmt
						}
					}

					// 개인사업자 전자세금계산서 발행 정보 CWMJPRO-670
					const inputParams = this.$store.getters['verify/custInputParams']
					const custType = this.$store.state['verify'].customer.custType
					if (custType === 'L' && inputParams?.billInfo?.notIsInputLater) {
						const billInfo = inputParams.billInfo
						const postCode = billInfo.checkboxAddress
							? itSetup.POST_CODE.replaceAll('-', '')
							: billInfo.zipCode
						itSetup.ETIZIP1 = postCode.slice(0, 3) // 우편번호1
						itSetup.ETIZIP2 = postCode.slice(3, 6) // 우편번호2
						itSetup.ETIADDR1 = billInfo.checkboxAddress
							? itSetup.ADDR1
							: billInfo.addr1 //  주소1
						itSetup.ETIADDR2 = billInfo.checkboxAddress
							? itSetup.ADDR2
							: billInfo.addr2 //  주소2
						itSetup.ETISTCD2 = inputParams.usrLtdNo // 사업자번호
						itSetup.ETISTCD_NM = inputParams.name //  상호
						itSetup.ETIKFREPRE = billInfo.ownerName // 대표자명
						itSetup.ETIKFTBUS = inputParams.kftbus // 업태
						itSetup.ETIKFTIND = inputParams.kftind // 업종
						itSetup.ETIFXDAY = billInfo.dayCode //발행일자
						itSetup.ETICD = billInfo.linkedBusiness //연계사업자
						itSetup.ETICD_NM = billInfo.linkedBusinessItem.text //연계사업자명
						itSetup.ETICN = billInfo.adminName //담당자이름
						itSetup.ETICM = billInfo.adminEmail //담당자이메일
						itSetup.ETICT = billInfo.adminTel //담당자연락처
						itSetup.ETICH = billInfo.adminPhone //담당자핸드폰
						itSetup.ETIFC = '' //회비발행
						itSetup.ETIGC = '' //상품대금발행
						itSetup.BILL_REQ_YN = 'Y' // 세금계산서발행여부
					}

					IT_SETUP.push(itSetup)

					deliveryInfo.zipSeq = itSetup.ZIP_SEQ
					// 사전계약 배정진행을 위한 값 세팅
					if (this.isPreContract) {
						deliveryInfo.zipCode = itSetup.POST_CODE
						deliveryInfo.address = itSetup.ADDR1
					}
					deliveryInfo.rentalYears = pay.options.agreeTerm
						? pay.options.agreeTerm.USE_DUTY_MONTH
						: ''
					//endregion

					if (preOrder && preOrder.RTN_YN === 'M') {
						excludedReturnOrders.push(index + 1)
					}

					if (
						preOrder &&
						preOrder.RTN_YN === 'A' &&
						!pay.options.returnPenaltyApplied
					) {
						excludedReturnOrders.push(index + 1) //반납접수 제외 목록 추가
					}
				})

				// [BEGIN] 기본 정보 생성
				const I_BASE_INFO = {}
				// [END] 기본 정보 생성

				//코디매칭 대상 여부
				I_BASE_INFO.ZCODY_MATCH = webParams.codyMatch === 'Y' ? 'X' : ''
				//코디매칭 매칭ID
				I_BASE_INFO.MAT_ID =
					webParams.codyMatch === 'Y' ? order.customer.matchingId : ''
				//코디매칭 INBOUND값
				I_BASE_INFO.INBOUND =
					webParams.codyMatch === 'Y' ? order.customer.inbound : ''
				// 사전계약 여부
				I_BASE_INFO.PRE_CONTRACT = this.isPreContract ? 'X' : ''

				const custType = this.$store.state['verify'].customer.custType

				const orderInfoParams = {
					I_SYS_ID: 'M',
					I_REGER_NO: codyNo,
					USER_ID: codyNo,
					COM_CD_INS: '2000',
					MOTI_CD: 'N',
					CONTACT_REQ_DT: moment().format('YYYYMMDD'), // 주문접수일자
					CUST_TYPE: custType
				}

				if (custType === 'L') {
					const inputParams = this.$store.getters['verify/custInputParams']
					Object.assign(orderInfoParams, {
						IMAGE_PATH: inputParams.image.res.filepath, // 사업자등록증 이미지 경로
						IMAGE_NO: inputParams.image.res.proId // 사업자등록증 이미지 번호
					})
				}

				//region deliveryInfos 업데이트
				let deliveryInfos = []
				if (this.checkedAll) {
					deliveryInfos = this.allSameItems.deliveryInfos
				} else {
					deliveryInfos = this.deliveryInfos
				}

				const orderChkSmartGoodsList = []
				IT_PROD.forEach(goods => {
					const info = {
						GOODS_ID: goods.GOODS_ID,
						GOODS_CD: goods.GOODS_CD,
						CONTRACT_ID: goods.CONTRACT_ID,
						SALE_ID: goods.SALE_ID,
						USER_SEQ: goods.ORDER_SEQ,
						ORDER_NO: goods.PRE_ORDER_NO ? goods.PRE_ORDER_NO : ''
					}
					orderChkSmartGoodsList.push(info)
				})

				const orderChkSmartCustomerList = []
				IT_SETUP.forEach((setup, i) => {
					let postCode = setup.POST_CODE.replace('-', '')
					if (postCode < 6) postCode = postCode.padStart(6, '0')

					const orderChkSmartGoods = orderChkSmartGoodsList.filter(
						v => v.USER_SEQ === i + 1 && v.GOODS_ID === '001'
					)

					const info = {
						USER_SEQ: orderChkSmartGoods[0]?.USER_SEQ || 0,
						ORDER_NO: orderChkSmartGoods[0]?.ORDER_NO || '',
						CONTRACT_CUST_ID: custNo,
						USE_CUST_ID: custNo,
						CREDIT_STATUS: 'Y',
						ZIP_SEQ: setup.ZIP_SEQ,
						ZIP1: postCode.substr(0, 3),
						ZIP2: postCode.substr(3, 6),
						ADDR1: setup.ADDR1,
						ADDR2: setup.ADDR2
					}
					orderChkSmartCustomerList.push(info)
				})

				const orderChkSmartData = {
					I_PAY_MTHD: IT_PAYMENT[0].PAY_MTHD,
					I_CARD_NO: IT_PAYMENT[0].CARD_NO,
					I_REGER_NO: codyNo,
					I_XC_PERNR: codyNo,
					T_GOODS_LIST: orderChkSmartGoodsList,
					T_CUSTOMER: orderChkSmartCustomerList
				}

				const result = await getOrderChkSmart(orderChkSmartData)

				let returnChk = false
				if (result.resultObject.T_OUT_LIST.length > 0) {
					await this.$confirm({
						message: result.resultObject.T_OUT_LIST[0].MSG_TXT,
						okText: '확인',
						cancelText: '취소'
					}).then(res => {
						if (!res) returnChk = true
					})
				}

				if (returnChk) return

				Object.assign(orderInfoParams, {
					mobOrderNo: this.mobOrderNo,
					excludedReturnOrders,
					IT_PROD,
					IT_SETUP,
					IT_PAYMENT,
					IT_PACKCOMBI,
					I_BASE_INFO,
					preOwnerYm: this.isPreContract
						? this.payList[0].options.preOwnerYm
						: '',
					deliveryInfos,
					checkedAll: this.checkedAll,
					allSameItems: this.allSameItems,
					setupInfos: this.setupInfos,
					funeralProducts
				})

				if (IT_ACCEPT.length > 0) {
					orderInfoParams.IT_ACCEPT = IT_ACCEPT
				}

				this.$log('=================================================')
				this.$log('orderInfoParams', orderInfoParams)
				this.$log('excludedReturnOrders', excludedReturnOrders)
				this.$log('=================================================')

				this.payList.forEach((pay, i) => {
					this.setupInfos[i].pay = pay
				})

				webParams.orderInfoJson = JSON.stringify(
					Object.assign(JSON.parse(webParams.orderInfoJson), {
						checkedAll: this.checkedAll,
						setupInfoList: this.setupInfos,
						products: this.$store.getters['pay/payProducts']
					})
				)

				Object.assign(orderInfoParams, {
					orderInfoJson: webParams.orderInfoJson
				})

				const postOrderRes = await postOrder(orderInfoParams)
				const postOrderRtn = postOrderRes.resultObject.data.E_RETURN
				this.monthList = postOrderRes.resultObject.data.IT_3MON_CHECK

				if (postOrderRtn.TYPE === 'T') {
					if (this.monthList.length > 0) {
						await this.open()
					}

					const orderInfoResult = postOrderRes.resultObject.data.IT_OUT_ORDER

					const deliveryInfos = postOrderRes.resultObject.deliveryInfos
					await this.$store.dispatch('inst/setOrderInfoResult', orderInfoResult)
					await this.$store.dispatch('schedule/setDeliveryInfos', deliveryInfos)
					await this.$store.dispatch(
						'ec/setFuneralProducts',
						postOrderRes.resultObject.funeralProducts || []
					)

					await this.$router.push({ name: 'order-ec' })
				} else if (postOrderRtn.TYPE === 'F' && postOrderRtn.CODE === 'E001') {
					// 주문불가
					await this.$alert({ message: postOrderRtn.MESSAGE })

					webParams.step = '10'
					webParams.statusCode = '201' // 주문불가
					webParams.orderInfoJson = JSON.stringify(
						Object.assign(JSON.parse(webParams.orderInfoJson), {
							setupInfoList: this.setupInfos,
							orderInfoParams,
							postOrderRtn
						})
					)
					await postWebOrderInfoById(this.mobOrderNo, webParams)

					await this.$router.push({ name: 'order-status' })
				} else {
					await this.$alert({ message: postOrderRtn.MESSAGE })
				}
			} catch (err) {
				this.$log(err)
				this.$alert({
					message:
						'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
				})
			}
		},
		getRecpTp(pay, payOptions) {
			this.$log('GET RECP TP ====>>>', pay, payOptions)
			let recpTp = ''
			if (pay.esProd.CONTRACT_ID === '1') {
				this.$log('CONTRACT_ID 1')
				if (pay.esProd.ORD_TYPE === 'Z004') {
					//자체할부
					this.$log('자체할부')
					if (
						payOptions.installmentMonth === 36 ||
						payOptions.installmentMonth === 60
					) {
						this.$log('자체할부 36개월, 60개월')
						recpTp = '81'
					} else if (
						payOptions.installmentMonth === 3 ||
						payOptions.installmentMonth === 6 ||
						payOptions.installmentMonth === 12
					) {
						this.$log('자체할부 3개월, 6개월, 12개월')
						recpTp = '61'
					} else {
						recpTp = ''
					}
				} else {
					// 일시불
					this.$log('일시불, 일시불보상')
					// 홈체험 상품의 경우에는 E1로 전송
					const goodsCd = pay.esProd.GOODS_CD.replace(/(^0+)/, '')
					recpTp = ['6099809', '6099805'].includes(goodsCd) ? 'E1' : '01'
				}
			} else {
				// 렌탈
				this.$log('CONTRACT_ID 1')
				if (pay.esProd.GOODS_ID === '001') {
					this.$log('주상품')
					if (pay.esProd.LESGB === 'L') {
						this.$log('L')
						recpTp = '75'
					} else {
						this.$log('R')
						recpTp = '03'
					}
				} else {
					this.$log('기타')
					recpTp = '01'
				}
			}

			this.$log('recpTp: ', recpTp)
			return recpTp
		},

		handleCheckedDefault(i, e) {
			this.setupInfos[i].default = e
		},
		openAddrList(i) {
			if (i === 'common') {
				this.$refs['commonAddrList'].open()
			} else {
				this.$refs['addrList' + i][0].open()
			}
		},
		openRecommendList(i) {
			if (i === 'common') {
				this.$refs['commonRecommedList'].open()
			} else {
				this.$refs['recommedList' + i][0].open()
			}
		},
		async selectRecommendSales(obj) {
			let altRes = true

			const selectedRecommnedSales = this.checkedAll
				? this.allSameItems.recommnedSales.PERNR?.length > 0
				: this.setupInfos[obj.index].recommnedSales.PERNR?.length > 0

			if (
				obj.salesInfo.preCodyNo &&
				obj.salesInfo.preCodyNo !== obj.salesInfo.PERNR
			) {
				altRes = await this.$refs.recommendConfirmInputPop.open('')
				if (altRes) obj.salesInfo.message = altRes
			} else if (selectedRecommnedSales) {
				altRes = await this.$refs.recommendConfirmPop.open(
					'입력된 추천판매인을 수정 하시겠습니까?'
				)
			}

			if (!altRes) return

			if (this.checkedAll) {
				this.allSameItems.recommnedSales = {
					...obj.salesInfo
				}
				this.$refs['commonRecommedList'].close()
			} else {
				this.setupInfos[obj.index].recommnedSales = { ...obj.salesInfo }
				this.$refs['recommedList' + obj.index][0].close()
			}
		},
		async selectAddress(i, obj) {
			const result = await fetchRegionalSpcText(obj.addr.ZIP_SEQ)
			const items = this.checkedAll ? this.allSameItems : this.setupInfos[i]

			items.address = obj.addr
			items.addrFull = `${obj.addr.ADDR1} ${obj.addr.ADDR2}`
			items.addr = undefined
			items.zipSeq = obj.addr.ZIP_SEQ
			items.regionalSpcText = result.resultObject.E_DESC_TXT

			// 설치희망일시가 선택되어있는 경우 주소가 변경되면 초기화 되어야 한다.
			let changeAlertFlag = false
			if (
				this.checkedAll &&
				(this.allSameItems.scheduleInfos.serviceManager.bookingDate !==
					undefined ||
					this.allSameItems.scheduleInfos.delegate3PL.bookingDate !==
						undefined ||
					this.allSameItems.scheduleInfos.air3PL.bookingDate !== undefined)
			) {
				this.allSameItems.scheduleInfos.serviceManager.bookingDate = undefined
				this.allSameItems.scheduleInfos.delegate3PL.bookingDate = undefined
				this.allSameItems.scheduleInfos.air3PL.bookingDate = undefined
				changeAlertFlag = true
			} else if (
				this.deliveryInfos[i].workerType !== 'EXPRESS' &&
				this.deliveryInfos[i].bookingDate !== undefined
			) {
				this.deliveryInfos[i].bookingDate = undefined
				changeAlertFlag = true
			}

			if (changeAlertFlag) {
				await this.$alert({
					message:
						"주소지가 변경되었습니다.<br/>'설치희망일시'를 다시 선택해 주세요."
				})
			}
		},

		initAppPage() {
			registerCallbackToApp(
				{
					cbPhysicalBack: 'cbPhysicalBack'
				},
				this
			)
		},

		async cbPhysicalBack() {
			await this.ListBack()
		},

		async ListBack() {
			if (this.isParameterEmpty(this.mobOrderNo)) return
			const res = await mOrderInfoByEtc({
				id: this.mobOrderNo, // 웹주문번호
				statusCode: '105'
			})

			const msg = getMessageByStatusCode(res.resultObject.statusCode)
			if (msg !== undefined) {
				await this.$alert({
					message: msg
				})
				sessionStorage.removeItem('customer')
				this.$router
					.push({
						name: 'order-status',
						query: {
							user_no: sessionStorage.getItem('codyNoEnc'),
							type: this.$store.getters['common/type']
						}
					})
					.catch()
			} else {
				const res = await fetchWebOrderInfoById(this.mobOrderNo)
				let webParams = res.resultObject
				let json = JSON.parse(webParams.orderInfoJson)
				json = Object.assign(json, {
					recp: {
						recpInfos: {}
					}
				})
				webParams.orderInfoJson = JSON.stringify(json)
				webParams.step = '04' //수납정보
				webParams.statusCode = '105' //수납입력
				await postWebOrderInfoById(this.mobOrderNo, webParams)
				await this.$router.push({ name: 'recp-method' })
			}
		},

		envInfoChk(i, pay) {
			const result = getEnvInfoActive(pay.options.prdha)
			if (!result) {
				if (this.checkedAll) {
					this.allSameItems.hasEnvInfoArr[i] = !result
				} else {
					this.hasEnvInfoArr[i] = !result
				}
			}
			return result
		},
		changeCheckedAll() {
			this.checkedAll = !this.checkedAll
		},
		async handleSetupDeliveryTypeSelected(i, item) {
			const deliveryItems = this.checkedAll
				? this.allSameItems.deliveryItem.reqInfo
				: this.deliveryItems[i].reqInfo

			const setupInfos = this.checkedAll
				? this.allSameItems
				: this.setupInfos[i]

			// 초기화
			deliveryItems.typeCode = ''
			deliveryItems.displayText = ''
			setupInfos.deliveryMemo = ''

			// 메시지 직접 입력하기
			deliveryItems.show = item.code === '1'

			if (item.code === '6') {
				// 선택 안함
				deliveryItems.type = undefined
			} else {
				deliveryItems.type = item
				deliveryItems.typeCode = item.code
				deliveryItems.displayText = item.text
				setupInfos.deliveryMemo = item.code === '1' ? '' : item.text
			}
		},
		async submitValidate() {
			const errorText = {
				phone: '휴대폰번호를 입력해 주세요.',
				addr: '주소를 입력해 주세요.',
				booking: '설치희망일시를 선택해 주세요.',
				envInfo: '설치환경정보를 입력해 주세요.',
				memo:
					'설치메모에 이모티콘 작성이 불가합니다.<br/>설치메모를 확인해주세요.'
			}
			let msg = ''

			// 이모티콘 정규식
			const regExp = /[\u{1f300}-\u{1f5ff}\u{1f900}-\u{1f9ff}\u{1f600}-\u{1f64f}\u{1f680}-\u{1f6ff}\u{2600}-\u{26ff}\u{2700}-\u{27bf}\u{1f1e6}-\u{1f1ff}\u{1f191}-\u{1f251}\u{1f004}\u{1f0cf}\u{1f170}-\u{1f171}\u{1f17e}-\u{1f17f}\u{1f18e}\u{3030}\u{2b50}\u{2b55}\u{2934}-\u{2935}\u{2b05}-\u{2b07}\u{2b1b}-\u{2b1c}\u{3297}\u{3299}\u{303d}\u{00a9}\u{00ae}\u{2122}\u{23f3}\u{24c2}\u{23e9}-\u{23ef}\u{25b6}\u{23f8}-\u{23fa}]/gu

			if (this.checkedAll) {
				// 다건 동일
				const envOrderNoArr = []
				const bookingGroupNameArr = []
				if (this.allSameItems.phone.length < 1) {
					msg = errorText.phone
				} else if (this.allSameItems.addrFull.length < 1) {
					msg = errorText.addr
				} else if (regExp.test(this.allSameItems.memo)) {
					msg = errorText.memo
				} else {
					this.payList.forEach((v, i) => {
						// 설치희망일시
						if (
							this.allSameItems.deliveryInfos[i].workerType ===
								'SERVICE_MANAGER' &&
							this.allSameItems.scheduleInfos.serviceManager.bookingDate ===
								undefined
						) {
							bookingGroupNameArr.push('서비스매니저')
						} else if (
							this.allSameItems.deliveryInfos[i].workerType ===
								'DELEGATE_3PL' &&
							this.allSameItems.scheduleInfos.delegate3PL.bookingDate ===
								undefined
						) {
							bookingGroupNameArr.push('외부업체(대형제품)')
						} else if (
							this.allSameItems.deliveryInfos[i].workerType === 'AIR_3PL' &&
							this.allSameItems.scheduleInfos.air3PL.bookingDate === undefined
						) {
							bookingGroupNameArr.push('외부업체(청정기)')
						}

						// 설치환경정보
						if (
							this.allSameItems.deliveryInfos[i].workerType !== 'EXPRESS' &&
							!this.allSameItems.hasEnvInfoArr[i]
						) {
							envOrderNoArr.push('주문' + (i + 1))
						}
					})

					if (bookingGroupNameArr.length > 0 && !this.isPreContract) {
						msg =
							[...new Set(bookingGroupNameArr)].join(', ') +
							' ' +
							errorText.booking
					} else if (envOrderNoArr.length > 0) {
						msg = envOrderNoArr.join(', ') + ' ' + errorText.envInfo
					}
				}
			} else {
				// 다건 개별
				this.payList.some((v, i) => {
					let orderNoText =
						this.payList.length > 1 ? '주문' + (i + 1) + '의 ' : ''

					if (this.setupInfos[i].phone.length < 1) {
						msg = orderNoText + errorText.phone
					} else if (this.setupInfos[i].addrFull.length < 1) {
						msg = orderNoText + errorText.addr
					} else if (
						!this.isPreContract &&
						this.deliveryInfos[i].workerType !== 'EXPRESS' &&
						this.deliveryInfos[i].bookingDate === undefined
					) {
						msg = orderNoText + errorText.booking
					} else if (
						this.deliveryInfos[i].workerType !== 'EXPRESS' &&
						!this.hasEnvInfoArr[i]
					) {
						msg = orderNoText + errorText.envInfo
					} else if (regExp.test(this.setupInfos[i].memo)) {
						msg = errorText.memo
					}

					// msg 존재 시 break
					return msg.length > 0
				})
			}

			return msg
		},
		deliveryMemoMaxLengthCheck(event, index) {
			// AOS textarea maxlength 적용이 안되어 메소드로 적용
			const maxlength = 20
			if (this.checkedAll) {
				this.allSameItems.deliveryMemo = event.target.value.slice(0, maxlength)
			} else {
				this.setupInfos[index].deliveryMemo = event.target.value.slice(
					0,
					maxlength
				)
			}
		},
		preSubGuideOpen() {
			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
				this.popPreSubsShow = true
			})
		},
		// 사전 계약 관리판매인 변경 체크
		async preContractSaleChk() {
			if (!this.isPreContract) return true // 사전계약이 아니면 Pass

			const preOrderList = []
			const products = this.$store.getters['pay/payProducts']
			products.forEach(product => {
				if (product.options.preOrder !== undefined)
					preOrderList.push(product.options.preOrder.ORDER_NO)
			})

			if (preOrderList.length > 0) {
				return await getPreContractSalesChk({
					saleCd: this.$store.getters['common/codyNo'],
					orderNos: [...preOrderList],
					mobOrderNo: this.$store.getters['verify/mobOrderNo']
				})
					.then(async res => {
						const { result } = res.resultObject
						if (result !== 'T') {
							// 주문 삭제
							await this.$alert({
								message:
									'선택된 기존계정의 관리판매인이 변경된 것으로 확인되어 해당 주문이 삭제되었습니다. '
							})
							const mobOrderNo = this.$store.getters['verify/mobOrderNo']

							const res = await fetchWebOrderInfoById(mobOrderNo)
							const webParams = res.resultObject
							webParams.statusCode = '203'

							postWebOrderInfoById(mobOrderNo, webParams).then(postRes => {
								this.$log('postRes: ', JSON.stringify(postRes))
								// 모바일 주문현황으로 이동
								this.$router.push({ name: 'order-status' })
							})
							return false
						} else {
							return true
						}
					})
					.catch(err => {
						this.$log(err)
						this.$alert(
							'일시적인 통신 장애로 서비스에 접속할 수 없습니다. 문제가 계속된다면, 담당자에게 문의해 주세요.'
						)
					})
			}

			return true
		},
		getDefaultGoodsCodeList(pay) {
			let saleId = pay.esProd.SALE_ID
			if (
				pay.options.sellType.ORD_TYPE === 'Z001' &&
				['P', 'PK', 'FP', 'FT'].includes(pay.payOptions.orderType)
			) {
				saleId = pay.payOptions.ET_INPUT.SALE_ID
			}

			return ''.concat(pay.prd.erp_cd, '#', pay.esProd.CONTRACT_ID, '#', saleId)
		},
		async getPreRecommendtSalesInfo() {
			const result = await getRecommendSalesInfo({
				phoneNumber: this.$store.getters['verify/custPhone']
			})

			if (result.resultObject.data?.preCodyNo) {
				this.isToastActive = true
				const recommnedSales = result.resultObject.data
				Object.assign(recommnedSales, {
					PERNR: recommnedSales.preCodyNo,
					ENAME: recommnedSales.preCodyName,
					NUM02: recommnedSales.preCodyPhone,
					ZVKGTX_H3: recommnedSales.preCodyDept,
					MEDICAL_YN: recommnedSales.preMedicalYn
				})

				this.allSameItems.recommnedSales = Object.assign(
					this.allSameItems.recommnedSales,
					{ ...recommnedSales }
				)
				this.setupInfos.forEach(v =>
					Object.assign(v.recommnedSales, {
						...recommnedSales
					})
				)
			}
		},
		async recommnedSalesDelete(idx) {
			const recommnedSales =
				idx === 'common'
					? this.allSameItems.recommnedSales
					: this.setupInfos[idx].recommnedSales

			let altRes = false
			if (!recommnedSales.preCodyNo) {
				altRes = await this.$refs.recommendConfirmPop.open(
					'입력된 추천판매인을 삭제 하시겠습니까?'
				)
			} else {
				altRes = await this.$refs.recommendConfirmInputPop.open('D')
				if (altRes) this.$set(recommnedSales, 'message', altRes)
			}
			if (!altRes) return
			this.$delete(recommnedSales, 'PERNR')
			this.$delete(recommnedSales, 'ENAME')
			this.$delete(recommnedSales, 'NUM02')
			this.$delete(recommnedSales, 'ZVKGTX_H3')
			this.$delete(recommnedSales, 'MEDICAL_YN')
		}
	}
}
</script>

<style scoped lang="scss">
/**
	* @description 버튼 포커스 배경색이 계속 남아있는 현상
*/
.theme--light.v-btn:focus::before {
	opacity: 0 !important;
}
.v-btn:before {
	background-color: transparent !important;
}
.v-btn:not(.v-btn--text):not(.v-btn--outlined):focus:before {
	opacity: 0 !important;
}
</style>
